import React from 'react';
import { Box, Grid, LinearProgress } from '@material-ui/core';

import { Translation } from 'common/Translation';

function LazyLoad({ name }) {
    return (
        <div className="lazy-load">
            <LinearProgress
                classes={{
                    colorPrimary: 'linear-color',
                    barColorPrimary: 'bar-color',
                }}
            />
            <Box display="flex">
                <Grid direction="column" justifyContent="center" alignItems="center" spacing={4} container>
                    <Grid item>
                        <img src={require('assets/img/logo-a.png')} alt={name} />
                    </Grid>
                    <Grid item>{Translation({ variable: 'general.loading' })}</Grid>
                </Grid>
            </Box>
        </div>
    );
}

LazyLoad.defaultProps = {
    name: null,
};

export default LazyLoad;
