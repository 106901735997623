import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Link, Tooltip } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import dateFormat from 'dateformat';
import Calendar from 'color-calendar';
import 'color-calendar/dist/css/theme-glass.css';

import { ApiGet } from 'common/Api';
import { GetCurrencySymbol } from 'common/Currency';
import { TourEndpoint } from 'common/Endpoint';
import { HandleMaskPhoneNumber } from 'common/Phone';
import { Translation } from 'common/Translation';
import { SetInnerHTML } from 'common/Typography';
import Booking from 'components/Booking';
import NotFound from 'components/NotFound';
import PlacePreview from 'components/PlacePreview';
import RightBlock from 'components/RightBlock';
import SeoTags from 'components/SeoTags';

function TourOverview({
    hideLoading,
    match: {
        params: { slug },
        url,
    },
    showLoading,
    windowWidth,
}) {
    const history = useHistory();
    const [row, setRow] = React.useState(null);
    const [showTooltip, setShowTooltip] = React.useState([]);

    const handleShowUrl = (url) => (event) => {
        history.push(url);
        event.preventDefault();
    };

    const handleShowTooltip = (countryCode, boolean = null) => {
        if (windowWidth <= 600 && boolean !== null) {
            return;
        }

        setShowTooltip({
            ...showTooltip,
            [countryCode]: boolean !== null ? boolean : !(showTooltip[countryCode] || false),
        })
    };

    const getFlag = (countryCode, countryName) => {
        return <img src={`https://flagcdn.com/32x24/${countryCode.toLowerCase()}.png`} width="32" alt={countryName} />;
    };

    React.useEffect(() => {
        const endpoint = TourEndpoint(slug);

        showLoading();

        ApiGet(endpoint.substring(4)).then((response) => {
            hideLoading();
            if (response.status) {
                setRow(response.data);

                if (
                    response.data.data.type &&
                    response.data.data.type !== 2 &&
                    response.data.data.fields.date &&
                    response.data.data.fields.date.value?.length > 0
                ) {
                    const calendar = new Calendar({
                        id: '#tour-dates',
                        theme: 'glass',
                        customMonthValues: Translation({ variable: 'general.months' }),
                        customWeekdayValues: Translation({ variable: 'general.weeks' }),
                        startWeekday: 1,
                        disableDayClick: true,
                        border: '0.25rem solid #e2e8f0',
                        headerBackgroundColor: '#e2e8f0',
                        fontFamilyHeader: 'ProstoOne',
                        fontFamilyWeekdays: 'ProstoOne',
                        fontFamilyBody: 'ProstoOne',
                        calendarSize: 'small',
                        eventsData: response.data.data.fields.date.value.map((date) => {
                            return {
                                start: date,
                                end: date,
                            };
                        }),
                    });

                    calendar.setDate(response.data.data.fields.date.value[0]);
                }
            }
        });
    }, [slug]);

    return row && row.data ? (
        <section className="relative">
            <SeoTags title={row.meta.title} description={row.meta.description} image={row.meta.image} url={url} />
            <div className="py-10 bg-gray-300">
                <div className="container mx-auto">
                    <div className="px-4">
                        <h1 className="text-3xl font-semibold">{row.data.fields.title.value}</h1>
                    </div>
                </div>
            </div>
            <div className="container mx-auto">
                <div className="flex flex-wrap items-stretch mt-10">
                    <div className="w-full lg:w-9/12">
                        <div className="text-md font-light leading-relaxed px-4 mb-4">
                            <Grid container alignItems="stretch" spacing={2}>
                                <Grid item xs={12}>
                                    <img src={row.data.image.url} alt={row.data.title} />
                                </Grid>
                                <Grid item xs={12} className="tour-info-block bg-gray-100 border rounded-lg rounded-t-0">
                                    {row.data.fields.country && (
                                        <Grid item xs={12}>
                                            {row.data.fields.country.value.map((country) => {
                                                return (
                                                    country.fields?.iso2?.value && (
                                                        <Tooltip
                                                            open={showTooltip[country.fields.iso2.value] ? true : false}
                                                            onClick={() => handleShowTooltip(country.fields.iso2.value)}
                                                            onMouseEnter={() => handleShowTooltip(country.fields.iso2.value, true)}
                                                            onMouseOut={() => handleShowTooltip(country.fields.iso2.value, false)}
                                                            title={country.fields?.title?.value}
                                                            key={country.fields?.title?.value}
                                                            disableFocusListener
                                                        >
                                                            <span className="country-block">
                                                                <span className="flag">
                                                                    {getFlag(country.fields.iso2.value, country.fields?.title?.value)}
                                                                </span>
                                                            </span>
                                                        </Tooltip>
                                                    )
                                                );
                                            })}
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <i className={`fas fa-${row.data.type && row.data.type === 2 ? 'plane' : 'route'}`}></i>{' '}
                                        {row.data.fields.itinerary.value}
                                    </Grid>
                                    {row.data.fields.periods && row.data.fields.periods.value?.length > 0 && (
                                        <Grid item xs={12}>
                                            <div className="flex flex-wrap items-stretch">
                                                <div className="mr-2">
                                                    <i className="fas fa-calendar-alt"></i>
                                                </div>
                                                <div>
                                                    {row.data.fields.periods.value.map((period, index) => {
                                                        return (
                                                            <div key={index}>
                                                                {dateFormat(period.start, 'dd.mm.yyyy')} - {dateFormat(period.end, 'dd.mm.yyyy')}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <i className="fas fa-circle-notch"></i> {row.data.fields.duration?.value}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {row.data.transport && (
                                            <span className="text-xs font-semibold inline-block p-2 uppercase rounded text-gray-700 bg-purple-200 mr-1">
                                                {row.data.transport}
                                            </span>
                                        )}
                                        {row.data.catering && (
                                            <span className="text-xs font-semibold inline-block p-2 uppercase rounded text-gray-700 bg-blue-200 mr-1">
                                                {row.data.catering}
                                            </span>
                                        )}
                                        {row.data.occupancy && (
                                            <span className="text-xs font-semibold inline-block p-2 uppercase rounded text-gray-700 bg-red-200 mr-1">
                                                {row.data.occupancy}
                                            </span>
                                        )}
                                        <div className={`${windowWidth >= 450 ? '-mt-14' : 'mt-4'} float-right`}>
                                            <Booking
                                                parentEl={
                                                    <span className="block text-xl font-bold p-2 rounded bg-red-600 text-white mb-2 float-right cursor-pointer">
                                                        {row.data.fields.pfrom?.value == 1 && Translation({ variable: 'tour.labels.price_from' })}{' '}
                                                        {row.data.price} {GetCurrencySymbol(row.data.currency.code || null)}
                                                    </span>
                                                }
                                                tour={row.data.slug}
                                            />
                                            <Booking tour={row.data.slug} />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <h2 className="text-xl font-semibold py-4">{Translation({ variable: 'tour.labels.contact_manager' })}</h2>
                                    {['+380685479621', '+380964886095'].map((phone, index) => (
                                        <Link
                                            key={index}
                                            color="secondary"
                                            underline="none"
                                            className="text-md rounded p-2 tour-contact-manager"
                                            href={`viber://chat?number=${phone}`}
                                            target="_blank"
                                        >
                                            <i className="fab fa-viber text-lg leading-lg" />
                                            <span className="inline-block ml-2">{HandleMaskPhoneNumber(phone)}</span>
                                        </Link>
                                    ))}
                                </Grid>
                                {row.data.fields.hotel && row.data.fields.hotel.value && (
                                    <Grid item xs={12}>
                                        {row.data.fields.hotel && (
                                            <>
                                                {row.data.fields.hotel.value.map((hotel, key) => (
                                                    <div key={key} className="pt-4">
                                                        <PlacePreview
                                                            cover={hotel.fields.cover.value}
                                                            parent1={hotel.parent}
                                                            parent2={hotel.parent?.parent}
                                                            showMap={true}
                                                            size="12"
                                                            slug={hotel.slug}
                                                            stars={hotel.fields.stars.value}
                                                            title={hotel.fields.title.value}
                                                            about={hotel.fields.about?.value}
                                                            type="hotel"
                                                        />
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </Grid>
                                )}
                                {row.data.fields.program && (
                                    <Grid item xs={12} className="tour-program-block">
                                        <h2 className="text-xl font-semibold py-4">{Translation({ variable: 'tour.labels.program' })}</h2>
                                        {row.data.fields.program.value.map((program, key) => (
                                            <div key={key} className="pb-4">
                                                <div className="p-4 rounded border border-gray-400 bg-gray-100 text-gray-800">
                                                    <div className="p-4 mb-4 rounded bg-gray-700 text-white day-name">
                                                        <h3 className="text-md font-semibold">
                                                            {Translation({ variable: 'tour.labels.day_number', values: { day: key + 1 } })}
                                                        </h3>
                                                    </div>
                                                    <div className="text-description-font">{SetInnerHTML(program)}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </Grid>
                                )}
                                {row.data.fields.included_in_price && (
                                    <Grid item xs={12}>
                                        <h2 className="text-xl font-semibold py-4">{Translation({ variable: 'tour.labels.included_in_price' })}</h2>
                                        <div className="pb-4">
                                            <div className="p-4 rounded border border-green-400 bg-green-100 text-gray-800 text-description-font">
                                                {SetInnerHTML(row.data.fields.included_in_price.value)}
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                                {row.data.fields.addcharge && (
                                    <Grid item xs={12}>
                                        <h2 className="text-xl font-semibold py-4">{Translation({ variable: 'tour.labels.additional_charge' })}</h2>
                                        <div className="pb-4">
                                            <div className="p-4 rounded border border-red-400 bg-red-100 text-gray-800 text-description-font">
                                                {SetInnerHTML(row.data.fields.addcharge.value)}
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                                {row.data.fields.addinfo && row.data.fields.addinfo.value && (
                                    <Grid item xs={12}>
                                        {row.data.fields.addinfo.value.map((addinfo, key) => (
                                            <div key={key} className="py-4">
                                                <h2 className="text-xl font-semibold pb-4">{addinfo.label}</h2>
                                                <div className="p-4 rounded border border-gray-400 text-gray-800 text-description-font">
                                                    {SetInnerHTML(addinfo.value)}
                                                </div>
                                            </div>
                                        ))}
                                    </Grid>
                                )}
                                {/* {row.data.fields.country && row.data.fields.country && (
                                    <Grid item xs={12}>
                                        {row.data.fields.country.value.map((country, key) => (
                                            <span key={key} className="mr-1">
                                                {country.fields.title.value}
                                            </span>
                                        ))}
                                    </Grid>
                                )} */}
                                {/* {row.data.fields.region && (
                                    <Grid item xs={12}>
                                        {Object.keys(row.data.fields.region.value).map((key) => (
                                            <span
                                                key={key}
                                                className="text-xs font-semibold inline-block p-2 uppercase rounded text-black bg-gray-200 mr-1 mb-1"
                                            >
                                                {row.data.fields.region.value[key]}
                                            </span>
                                        ))}
                                    </Grid>
                                )} */}
                                <Grid item xs={12}>
                                    <div className="pt-2">
                                        <Alert severity="info">
                                            {Translation({ variable: 'general.request_info' })}
                                            <div className="mt-2">
                                                <Booking tour={row.data.slug} call />
                                            </div>
                                        </Alert>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div className="w-full lg:w-3/12">
                        {row.data.fields.vid && (
                            <div className="px-4 mb-4">
                                {Object.keys(row.data.fields.vid.value).map((key) => (
                                    <Link
                                        key={key}
                                        onClick={handleShowUrl(TourEndpoint(null, { vid: [key] }))}
                                        href={TourEndpoint(null, { vid: [key] })}
                                    >
                                        <span className="text-xs font-semibold inline-block p-2 uppercase rounded text-gray-700 bg-green-200 mr-1 mb-1">
                                            {row.data.fields.vid.value[key]}
                                        </span>
                                    </Link>
                                ))}
                            </div>
                        )}
                        {row.data.fields.event && (
                            <div className="px-4 mb-4">
                                {Object.keys(row.data.fields.event.value).map((key) => (
                                    <Link
                                        key={key}
                                        onClick={handleShowUrl(TourEndpoint(null, { event: [key] }))}
                                        href={TourEndpoint(null, { event: [key] })}
                                    >
                                        <span className="text-xs font-semibold inline-block p-2 uppercase rounded text-gray-700 bg-orange-200 mr-1 mb-1">
                                            {row.data.fields.event.value[key]}
                                        </span>
                                    </Link>
                                ))}
                            </div>
                        )}
                        {row.data.type && row.data.type !== 2 && row.data.fields.date && row.data.fields.date.value?.length > 0 && (
                            <div className="px-4">
                                <h2 className="text-xl font-semibold py-4">{Translation({ variable: 'tour.labels.calendar' })}</h2>
                                <div className="mb-4 text-center" id="tour-dates"></div>
                            </div>
                        )}
                        <RightBlock />
                    </div>
                </div>
            </div>
        </section>
    ) : (
        <NotFound />
    );
}

TourOverview.defaultProps = {
    hideLoading: () => {},
    match: {
        params: { slug: null },
        url: null,
    },
    showLoading: () => {},
    windowWidth: null,
};

export default TourOverview;
