const queryString = require('query-string');

import { GetCurrentLanguage } from 'common/Language';

const ConvertEndpoint = (endpoint, slug = null, parameters = {}) => {
    if (slug !== null) {
        endpoint += `/${slug}`;
    }

    const query = Stringify(parameters);
    if (query.length > 0) {
        endpoint += `?${query}`;
    }

    return GetEndpointByLanguage(endpoint);
};

export const ParseUrl = (url) => {
    return queryString.parseUrl(url, {arrayFormat: 'bracket'});
};

export const Stringify = (parameters) => {
    return queryString.stringify(parameters, {arrayFormat: 'bracket', skipNull: true});
};

export const GetEndpointByLanguage = (endpoint) => {
    return `/${GetCurrentLanguage()}/${endpoint}`;
};

export const PageEndpoint = (slug) => {
    return ConvertEndpoint(slug);
};

export const TourEndpoint = (slug = null, parameters = {}) => {
    return ConvertEndpoint('tours', slug, parameters);
};

export const NewsEndpoint = (slug = null, parameters = {}) => {
    return ConvertEndpoint('news', slug, parameters);
};

export const PlaceEndpoint = (slug = null, parameters = {}) => {
    return ConvertEndpoint('places', slug, parameters);
};
