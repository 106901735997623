import React from 'react';
import { connect } from 'react-redux';

import { ApiGet } from 'common/Api';
import { GetCurrentLanguage } from 'common/Language';
import { changeLanguage, setInitialDetails, setWindowDetails } from 'store/actions/Global';
import Layout from 'components/Layout';

function App({ changeLanguage, isMobile, setInitialDetails, setWindowDetails, windowWidth }) {
    const handleResize = React.useCallback(() => {
        setWindowDetails({
            isMobile: window.innerWidth < 768 ? true : false,
            windowWidth: window.innerWidth,
        });
    }, [setWindowDetails]);

    React.useEffect(() => {
        if (windowWidth > 0) {
            const pathArray = window.location.pathname.split('/');
            if (pathArray[1] && GetCurrentLanguage(pathArray[1]) === pathArray[1]) {
                changeLanguage(pathArray[1]);
            }

            ApiGet(`initial?mobile=${isMobile ? 1 : 0}`).then((response) => {
                if (response.status) setInitialDetails(response.data);
            });
        }
    }, [isMobile, setInitialDetails]);

    React.useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
    }, [handleResize]);

    return <Layout />;
}

App.defaultProps = {
    changeLanguage: () => {},
    isMobile: false,
    setInitialDetails: () => {},
    setWindowDetails: () => {},
    windowWidth: 0,
};

export default connect(
    (state) => {
        const {
            Global: { isMobile, windowWidth },
        } = state;

        return { isMobile, windowWidth };
    },
    (dispatch) => ({
        changeLanguage: (iso) => changeLanguage(iso)(dispatch),
        setInitialDetails: (details) => setInitialDetails(details)(dispatch),
        setWindowDetails: (details) => setWindowDetails(details)(dispatch),
    })
)(App);
