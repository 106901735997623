import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { TourEndpoint, NewsEndpoint } from 'common/Endpoint';
import { Translation } from 'common/Translation';
import BaseCarousel from 'components/BaseCarousel';
import ItTourSearchWidget from 'components/ItTourSearchWidget';
import NewsPreview from 'components/NewsPreview';
import PartnerBlock from 'components/PartnerBlock';
import SeoTags from 'components/SeoTags';
import ServiceButton from 'components/ServiceButton';
import TourPreview from 'components/TourPreview';

function Home({ banners, hot_offers, news, services_pages, special_offers }) {
    const history = useHistory();

    const handleShowUrl = (url) => (event) => {
        history.push(url);
        event.preventDefault();
    };

    useEffect(() => {
        (function(d, s, id) {
            if (d.getElementById(id)) {
                if (window.__TOMORROW__) {
                    window.__TOMORROW__.renderWidget();
                }
                return;
            }
            const fjs = d.getElementsByTagName(s)[0];
            const js = d.createElement(s);
            js.id = id;
            js.src = 'https://www.tomorrow.io/v1/widget/sdk/sdk.bundle.min.js';

            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'tomorrow-sdk');
    }, []);

    return (
        <>
            <SeoTags
                title={Translation({ variable: 'general.title' })}
                description={Translation({ variable: 'general.description' })}
                keywords={Translation({ variable: 'general.keywords' })}
            />
            <section className="relative">
                <BaseCarousel files={banners} />
            </section>

            <section className="relative">
                <div className="container mx-auto">
                    <ItTourSearchWidget />
                </div>
            </section>

            <section className="relative py-20">
                <div className="container mx-auto">
                    <div className="flex flex-wrap items-stretch">
                        <div className="w-full text-center mt-6 mb-6">
                            <h2 className="text-3xl font-semibold">{Translation({ variable: 'general.special_offers' })}</h2>
                        </div>
                        {special_offers.map((offer, key) => (
                            <TourPreview key={key} {...offer} />
                        ))}
                        {special_offers.length === 3 && (
                            <div className="w-full text-center mt-10">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleShowUrl(TourEndpoint('places'))}
                                    href={TourEndpoint('places')}
                                    // onClick={handleShowUrl(TourEndpoint(null, { offer: 1 }))}
                                    // href={TourEndpoint(null, { offer: 1 })}
                                >
                                    {Translation({ variable: 'general.all_offers' })}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </section>

            <section className="relative py-20 bg-gray-300">
                <div className="container mx-auto">
                    <div className="flex flex-wrap items-stretch">
                        <div className="w-full text-center mt-6 mb-6">
                            <h2 className="text-3xl font-semibold">{Translation({ variable: 'general.hot_offers' })}</h2>
                        </div>
                        {hot_offers.map((offer, key) => (
                            <TourPreview key={key} {...offer} />
                        ))}
                        {hot_offers.length === 3 && (
                            <div className="w-full text-center mt-10">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleShowUrl(TourEndpoint(null, { hot: 1 }))}
                                    href={TourEndpoint(null, { hot: 1 })}
                                >
                                    {Translation({ variable: 'general.all_offers' })}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </section>

            <section className="relative py-20">
                <div className="container mx-auto">
                    <div className="flex flex-wrap items-stretch">
                        <div className="w-full md:w-6/12 mt-6">
                            <div className="flex flex-wrap items-stretch">
                                <div className="w-full text-center mt-6 mb-6">
                                    <h2 className="text-3xl font-semibold">{Translation({ variable: 'general.services' })}</h2>
                                </div>
                                {services_pages.map((page, index) => (
                                    <ServiceButton
                                        bgcolor={
                                            ['gray-900', 'red-500', 'indigo-500', 'green-500', 'yellow-500', 'blue-500', 'orange-500', 'pink-500'][index]
                                        }
                                        slug={page.slug}
                                        key={index}
                                        textcolor="white"
                                        title={page.fields.header.value}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="w-full md:w-6/12 mt-6">
                            <div className="w-full text-center mt-6 mb-6">
                                <h2 className="text-3xl font-semibold">{Translation({ variable: 'general.weather' })}</h2>
                            </div>
                            <div className="w-full px-4 py-6">
                                <div className="tomorrow"
                                    data-location-id="031862,107978,000032,109304,022021,052666"
                                    data-language="UK"
                                    data-unit-system="METRIC"
                                    data-skin="light"
                                    data-widget-type="current6"
                                    style={{ paddingBottom:'22px', position: 'relative' }}
                                    >
                                    <a
                                        href="https://www.tomorrow.io/weather-api/"
                                        rel="nofollow noopener noreferrer"
                                        target="_blank"
                                        style={{ position: 'absolute', 'bottom': 0, 'transform': 'translateX(-50%)', left: '50%' }}
                                    >
                                        <img
                                        alt="Powered by the Tomorrow.io Weather API"
                                        src="https://weather-website-client.tomorrow.io/img/powered-by.svg"
                                        width="250"
                                        height="18"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="relative py-20 bg-gray-300">
                <div className="container mx-auto">
                    <div className="flex flex-wrap items-stretch">
                        <div className="w-full text-center mt-6 mb-6">
                            <h2 className="text-3xl font-semibold">{Translation({ variable: 'general.partners' })}</h2>
                        </div>
                        {[
                            {
                                title: 'Аккорд-тур',
                                image: 'akkord',
                            },
                            {
                                title: 'ALF',
                                image: 'alf',
                            },
                            // {
                            //     title: 'Алголь',
                            //     image: 'algol',
                            // },
                            {
                                title: 'Альянс',
                                image: 'alliance',
                            },
                            {
                                title: 'ANEX Tour',
                                image: 'anex',
                            },
                            {
                                title: 'Coral Travel',
                                image: 'coral',
                            },
                            {
                                title: 'Join UP!',
                                image: 'joinup',
                            },
                            {
                                title: 'KOMPAS Touroperator',
                                image: 'kompas',
                            },
                            {
                                title: 'Pegas Touristik',
                                image: 'pegas',
                            },
                            {
                                title: 'Танго Тревел',
                                image: 'tango',
                            },
                            {
                                title: 'Тез-тур',
                                image: 'teztour',
                            },
                            {
                                title: 'TPG',
                                image: 'tpg',
                            },
                            // {
                            //     title: 'TUI',
                            //     image: 'tui',
                            // },
                            {
                                title: 'Zabugor',
                                image: 'zabugor',
                            },
                        ].map((partner) => {
                            return <PartnerBlock {...partner} key={partner.title} />;
                        })}
                    </div>
                </div>
            </section>

            <section className="relative pt-20 lg:mb-0 mb-4">
                <div className="container mx-auto">
                    <div className="flex flex-wrap items-stretch">
                        <div className="w-full text-center mt-6 mb-6">
                            <h2 className="text-3xl font-semibold">{Translation({ variable: 'general.last_news' })}</h2>
                        </div>
                        {news.map((item, key) => (
                            <NewsPreview key={key} {...item} />
                        ))}
                        {news.length === 3 && (
                            <div className="w-full text-center mt-10">
                                <Button variant="outlined" color="primary" onClick={handleShowUrl(NewsEndpoint())} href={NewsEndpoint()}>
                                    {Translation({ variable: 'general.show_all' })}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

Home.defaultProps = {
    banners: [
        {
            title: null,
            url: '/',
            type: 1,
            file: null,
        },
    ],
    hot_offers: [
        {
            catering: null,
            currency: {},
            slug: null,
            image: {},
            fields: {},
            occupancy: null,
            price: 0,
            title: null,
        },
    ],
    news: [
        {
            date: new Date(),
            slug: null,
            image: {},
            title: null,
        },
    ],
    services_pages: [
        {
            bgcolor: null,
            slug: null,
            textcolor: null,
            title: null,
        },
    ],
    special_offers: [
        {
            catering: null,
            currency: {},
            slug: null,
            image: {},
            fields: {},
            occupancy: null,
            price: 0,
            title: null,
        },
    ],
};

export default Home;
