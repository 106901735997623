import { connect } from 'react-redux';

import { Translation } from 'common/Translation';
import { changeLanguage, hideLoading, setInitialDetails, showLoading } from 'store/actions/Global';
import Header from './Header';

const mapStateToProps = (state) => {
    const {
        Global: { about, contact, isMobile, name },
    } = state;

    return { about, contact, isMobile, name, tour_types: Translation({ variable: 'tour.type' }) };
};

const mapDispatchToProps = (dispatch) => ({
    changeLanguage: (iso) => changeLanguage(iso)(dispatch),
    hideLoading: () => hideLoading()(dispatch),
    setInitialDetails: (details) => setInitialDetails(details)(dispatch),
    showLoading: () => showLoading()(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
