import { connect } from 'react-redux';

import Booking from './Booking';

const mapStateToProps = (state) => {
    const {
        Global: { isMobile },
    } = state;

    return { isMobile };
};

export default connect(mapStateToProps, {})(Booking);

