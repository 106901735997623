export const TrimValueByLength = (value = null, chars = 16) => {
    if (value === null) {
        return value;
    }

    return value.length > chars ? value.substring(0, chars) + '...' : value;
};

export const SetInnerHTML = (html = null) => {
    if (html === null) {
        return html;
    }

    return (
        <div
            className="inner-html"
            dangerouslySetInnerHTML={{
                __html: html,
            }}
        />
    );
};
