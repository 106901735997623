import { connect } from 'react-redux';

import PopupBlock from './PopupBlock';

const mapStateToProps = (state) => {
    const {
        Global: {
            blocks: { popup },
        },
    } = state;

    let banner = null;

    if (popup && popup.length > 0 && popup[0].banners && popup[0].banners[0]) {
        banner = popup[0].banners[0];
    }

    return { ...banner };
};

export default connect(mapStateToProps, {})(PopupBlock);
