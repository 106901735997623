import React, { useEffect } from 'react';

function ItTourHotTourWidget() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.ittour.com.ua/showcase_search.jsx?id=37267D2848G736581M436&type=103&num=1';
        script.id = 'itTourHotPriceWidgetScriptJsx';
        document.body.appendChild(script);

        setTimeout(() => {
            showcase_hottour_v2_showcase_load_css();
        }, 2 * 1000);
    }, []);

    return <div id="showcase_hottour_v2_showcase_module" />;
}

export default ItTourHotTourWidget;
