import React from 'react';
import { Chip, Collapse, Grid, Link, List, ListItem, ListItemText, MenuItem, MenuList } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/AlternateEmail';
import PublicIcon from '@material-ui/icons/Public';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { ApiGet } from 'common/Api';
import { PlaceEndpoint } from 'common/Endpoint';
import { HandleMaskPhoneNumber } from 'common/Phone';
import { GetEmbedMapCode } from 'common/Maps';
import { Translation } from 'common/Translation';
import { SetInnerHTML } from 'common/Typography';
import NotFound from 'components/NotFound';
import PlacePhotos from 'components/PlacePhotos';
import SeoTags from 'components/SeoTags';

function HotelOverview({
    hideLoading,
    match: {
        params: { country, city, hotel: slug },
        url,
    },
    showLoading,
    windowWidth,
}) {
    const [row, setRow] = React.useState(null);
    const [selectedMenuItem, setSelectedMenuItem] = React.useState('about');
    const [selectedMenuItems, setSelectedMenuItems] = React.useState([selectedMenuItem]);

    const handleShowMenu = (field) => (event) => {
        const filtered = selectedMenuItems.indexOf(field);
        if (filtered > -1) {
            selectedMenuItems.splice(filtered, 1);
        } else {
            selectedMenuItems.push(field);
        }
        setSelectedMenuItems([...selectedMenuItems]);

        setSelectedMenuItem(field);
        event.preventDefault();
    };

    const hotelFields = ['about', 'location', 'territory', 'inside', 'room', 'entsport', 'child', 'catering', 'barres', 'beach', 'cards', 'video'];

    React.useEffect(() => {
        const endpoint = PlaceEndpoint(slug);

        showLoading();

        ApiGet(endpoint.substring(4)).then((response) => {
            hideLoading();

            if (
                response.status &&
                response.data.data.parent &&
                response.data.data.parent.slug === city &&
                response.data.data.parent.parent &&
                response.data.data.parent.parent.slug === country
            ) {
                response.data.data.title = `${response.data.data.fields.title.value} ${response.data.data.fields.stars.value}`;

                setRow(response.data);

                hotelFields.some((field, key) => {
                    if (response.data.data.fields[field] && response.data.data.fields[field].value) {
                        setSelectedMenuItem(field);
                        return true;
                    }
                });
            }
        });
    }, [slug]);

    return row && row.data ? (
        <section className="relative">
            <SeoTags title={row.meta.title} description={row.meta.description} image={row.meta.image} url={url} />
            <div className="py-10 bg-gray-300">
                <div className="container mx-auto">
                    <div className="px-4">
                        <h1 className="text-3xl font-semibold">
                            {row.data.title}
                            {row.data.parent &&
                                row.data.parent.fields &&
                                row.data.parent.fields.title &&
                                row.data.parent.fields.title.value &&
                                `, ${row.data.parent.fields.title.value}`}
                            {row.data.parent &&
                                row.data.parent.parent &&
                                row.data.parent.parent.fields &&
                                row.data.parent.parent.fields.title &&
                                row.data.parent.parent.fields.title.value &&
                                `, ${row.data.parent.parent.fields.title.value}`}
                        </h1>
                    </div>
                </div>
            </div>
            <div className="container mx-auto">
                <div className="flex flex-wrap items-stretch mt-10">
                    <div className="w-full lg:w-9/12">
                        <div className="text-md font-light leading-relaxed px-4 mb-4">
                            <Grid container alignItems="stretch" spacing={2}>
                                <Grid item xs={12}>
                                    <PlacePhotos cover={row.data.fields.cover.value} title={row.data.title} photos={row.data.fields.photo?.value} />
                                </Grid>
                                {row.data.fields.vid && row.data.fields.vid.value && (
                                    <Grid item xs={12}>
                                        {row.data.fields.vid.value.map((value, key) => (
                                            <Chip key={key} className="mr-2 my-2 hotel-vid" label={value} />
                                        ))}
                                    </Grid>
                                )}
                                {windowWidth < 1024 ? (
                                    <Grid item xs={12}>
                                        <List component="nav">
                                            {hotelFields.map((field, key) => {
                                                return (
                                                    row.data.fields[field] &&
                                                    row.data.fields[field].value && (
                                                        <div key={key}>
                                                            <ListItem onClick={handleShowMenu(field)} button>
                                                                <ListItemText primary={Translation({ variable: `tour.labels.hotel.${field}` })} />
                                                                {selectedMenuItems.includes(field) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                            </ListItem>
                                                            <Collapse in={selectedMenuItems.includes(field)} timeout="auto" unmountOnExit>
                                                                <div className="py-4">
                                                                    <div className="p-4 rounded border border-gray-400 bg-gray-100 text-gray-800 text-description-font">
                                                                        {field === 'video'
                                                                            ? SetInnerHTML(
                                                                                  row.data.fields[field].value
                                                                                      .replace(/\bwidth="(\d+)"/g, 'width="100%"')
                                                                                      .replace(/\bheight="(\d+)"/g, 'height="200"')
                                                                              )
                                                                            : SetInnerHTML(row.data.fields[field].value)}
                                                                    </div>
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                    )
                                                );
                                            })}
                                        </List>
                                    </Grid>
                                ) : (
                                    hotelFields.map((field, key) => {
                                        return (
                                            selectedMenuItem === field &&
                                            row.data.fields[field] &&
                                            row.data.fields[field].value && (
                                                <Grid item xs={12} key={key}>
                                                    <div className="py-4">
                                                        <h2 className="text-xl font-semibold pb-4">
                                                            {Translation({ variable: `tour.labels.hotel.${field}` })}
                                                        </h2>
                                                        <div className="p-4 rounded border border-gray-400 bg-gray-100 text-gray-800 text-description-font">
                                                            {field === 'video'
                                                                ? SetInnerHTML(
                                                                      row.data.fields[field].value
                                                                          .replace(/\bwidth="(\d+)"/g, 'width="100%"')
                                                                          .replace(/\bheight="(\d+)"/g, 'height="400"')
                                                                  )
                                                                : SetInnerHTML(row.data.fields[field].value)}
                                                        </div>
                                                    </div>
                                                </Grid>
                                            )
                                        );
                                    })
                                )}
                            </Grid>
                        </div>
                    </div>
                    <div className="w-full lg:w-3/12">
                        {((row.data.fields.phone && row.data.fields.phone.value) ||
                            (row.data.fields.email && row.data.fields.email.value) ||
                            (row.data.fields.website && row.data.fields.website.value)) && (
                            <h3 className="text-xl font-semibold px-4 mb-4">{Translation({ variable: 'tour.labels.hotel.contacts' })}</h3>
                        )}
                        {row.data.fields.phone && row.data.fields.phone.value && (
                            <div className="px-4 mb-4">
                                <Link underline="none" color="inherit" href={`tel:${row.data.fields.phone.value}`}>
                                    <PhoneIcon /> {HandleMaskPhoneNumber(row.data.fields.phone.value)}
                                </Link>
                            </div>
                        )}
                        {row.data.fields.email && row.data.fields.email.value && (
                            <div className="px-4 mb-4">
                                <Link
                                    underline="none"
                                    color="inherit"
                                    // eslint-disable-next-line max-len
                                    href={`mailto:${row.data.fields.email.value}&subject=An inquiry from a Love Travel user for ${row.data.fields.title.value}`}
                                >
                                    <EmailIcon /> {row.data.fields.email.value}
                                </Link>
                            </div>
                        )}
                        {row.data.fields.website && row.data.fields.website.value && (
                            <div className="px-4 mb-4">
                                <Link underline="none" color="inherit" target="_blank" href={row.data.fields.website.value}>
                                    <PublicIcon /> {row.data.fields.website.value}
                                </Link>
                            </div>
                        )}
                        <div className="px-4 mb-4">
                            {GetEmbedMapCode({
                                child: row.data.fields.title.value,
                                parent1: row.data.parent?.fields?.title?.value,
                                parent2: row.data.parent?.parent?.fields?.title?.value,
                            })}
                        </div>
                        {windowWidth >= 1024 && (
                            <MenuList>
                                {hotelFields.map((field, key) => {
                                    return (
                                        row.data.fields[field] &&
                                        row.data.fields[field].value && (
                                            <MenuItem key={key} onClick={handleShowMenu(field)} selected={field === selectedMenuItem}>
                                                {Translation({ variable: `tour.labels.hotel.${field}` })}
                                            </MenuItem>
                                        )
                                    );
                                })}
                            </MenuList>
                        )}
                    </div>
                </div>
            </div>
        </section>
    ) : (
        <NotFound />
    );
}

HotelOverview.defaultProps = {
    hideLoading: () => {},
    match: {
        params: { country: null, city: null, hotel: null },
        url: null,
    },
    showLoading: () => {},
    windowWidth: null,
};

export default HotelOverview;
