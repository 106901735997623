import React from 'react';

import Banner from 'components/Banner';

function RightBlock({ banners }) {
    return (
        banners &&
        banners.length > 0 && (
            <section className="relative">
                <div className="container mx-auto">
                    <div className="flex flex-wrap items-stretch px-2">
                        {banners
                            .filter((banner) => banner.title && banner.type && banner.file)
                            .map((banner) => (
                                <div className="lg:w-auto w-6/12 lg:w-12 mb-4 px-2" key={banner.url}>
                                    <Banner {...banner} />
                                </div>
                            ))}
                    </div>
                </div>
            </section>
        )
    );
}

RightBlock.defaultProps = {
    banners: [
        {
            title: null,
            url: '/',
            type: 1,
            file: null,
        },
    ],
};

export default RightBlock;
