import { connect } from 'react-redux';

import { hideLoading, showLoading } from 'store/actions/Global';
import HotelOverview from './HotelOverview';

const mapStateToProps = (state) => {
    const {
        Global: { windowWidth },
    } = state;

    return { windowWidth };
};

const mapDispatchToProps = (dispatch) => ({
    hideLoading: () => hideLoading()(dispatch),
    showLoading: () => showLoading()(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HotelOverview);
