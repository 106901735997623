import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import Banner from 'components/Banner';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function BaseCarousel({ dynamic, files, selectedItem, showThumbs }) {
    const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;
    const customRenderThumbs = (children) => children;

    return (
        <Carousel
            autoPlay={true}
            infiniteLoop={true}
            interval="10000"
            transitionTime="400"
            dynamicHeight={dynamic}
            showStatus={false}
            useKeyboardArrows={true}
            showIndicators={!showThumbs}
            showThumbs={showThumbs}
            selectedItem={selectedItem}
            renderItem={customRenderItem}
            renderThumbs={customRenderThumbs}
            renderArrowPrev={(onClickHandler, hasPrev) =>
                hasPrev && <ArrowLeftIcon onClick={onClickHandler} className="control-arrow control-arrow-left" />
            }
            renderArrowNext={(onClickHandler, hasNext) =>
                hasNext && <ArrowRightIcon onClick={onClickHandler} className="control-arrow control-arrow-right" />
            }
        >
            {files
                .filter((file) => file.title && file.type && file.file)
                .map((file, index) => {
                    return <Banner key={`${file.file}-${index}`} index={index} {...file} />;
                })}
        </Carousel>
    );
}

BaseCarousel.defaultProps = {
    dynamic: false,
    files: [
        {
            title: null,
            url: '/',
            type: 1,
            file: null,
        },
    ],
    selectedItem: 0,
    showThumbs: false,
};

export default BaseCarousel;
