import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

import { PlaceEndpoint } from 'common/Endpoint';
import { GetEmbedMapCode } from 'common/Maps';
import { Translation } from 'common/Translation';
import { SetInnerHTML } from 'common/Typography';

function PlacePreview({ about, cover, parent1, parent2, showMap, size, slug, stars, title, type }) {
    const history = useHistory();

    const reGenerateEndpoint = () => {
        let endpoint = slug;

        if (parent1.slug) {
            endpoint = `${parent1.slug}/${endpoint}`;
        }
        if (parent2.slug) {
            endpoint = `${parent2.slug}/${endpoint}`;
        }

        return PlaceEndpoint(endpoint);
    };

    const handleClick = (event) => {
        history.push(reGenerateEndpoint());
        event.preventDefault();
    };

    return (
        <div className={`w-full lg:w-${size}/12 place-preview-block`}>
            <div className="flex flex-wrap items-stretch">
                <div className="w-full mr-auto ml-auto">
                    <div className="relative flex flex-col min-w-0 break-words w-full cursor-pointer" onClick={handleClick}>
                        {!showMap && (
                            <img
                                src={cover.url || '/'}
                                className="w-full align-middle rounded-t-lg"
                                alt={type === 'hotel' ? `${title} ${stars}` : title}
                            />
                        )}
                        <blockquote className="relative min-h-24">
                            {parent2.fields?.title?.value && (
                                <div className="text-lg text-gray-700">
                                    {parent2.fields?.title?.value}
                                    {parent1.fields?.title?.value && <>, {parent1.fields?.title?.value}</>}
                                </div>
                            )}
                            <div className="font-bold leading-relaxed">{type === 'hotel' ? `${title} ${stars}` : title}</div>
                            {about && <div className="text-gray-800 text-description-font">{SetInnerHTML(about)}</div>}
                            <div className={showMap ? 'my-4' : 'mt-4'}>
                                <Button size="small" variant="outlined" href={reGenerateEndpoint()}>
                                    {Translation({ variable: `tour.labels.${type === 'hotel' ? 'hotel_info_button' : 'place_info_button'}` })}
                                </Button>
                            </div>
                        </blockquote>
                        {showMap &&
                            GetEmbedMapCode({
                                child: title,
                                parent1: parent1.fields?.title?.value,
                                parent2: parent2.fields?.title?.value,
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
}

PlacePreview.defaultProps = {
    about: null,
    cover: {},
    parent1: {},
    parent2: {},
    showMap: false,
    size: 4,
    slug: null,
    stars: null,
    title: null,
    type: 'hotel',
};

export default PlacePreview;
