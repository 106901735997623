import React, { useEffect } from 'react';

function ItTourSearchWidget() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.ittour.com.ua/tour_search.jsx?id=154701DG73254524516M8&ver=3';
        script.id = 'itTourWidgetScriptJsx';
        document.body.appendChild(script);
    }, []);

    return <div id="itTourWidgetWrapper"></div>;
}

export default ItTourSearchWidget;
