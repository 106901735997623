import React from 'react';

import { ApiGet } from 'common/Api';
import { ParseUrl, TourEndpoint } from 'common/Endpoint';
import CountryBlock from 'components/CountryBlock';
import RightBlock from 'components/RightBlock';
import NotFound from 'components/NotFound';
import SeoTags from 'components/SeoTags';

function TourPlaces({ hideLoading, match: { url }, location: { search }, showLoading }) {
    const { query } = ParseUrl(search);
    const [rows, setRows] = React.useState(null);

    React.useEffect(() => {
        showLoading();

        ApiGet(TourEndpoint('countries', {type: query.type || null}).substring(4)).then((response) => {
            hideLoading();
            if (response.status) {
                setRows(response.data);
            }
        });
    }, []);

    return rows && rows.data ? (
        <section className="relative">
            <SeoTags title={rows.meta.title} description={rows.meta.description} url={url} />
            <div className="py-10 bg-gray-300">
                <div className="container mx-auto">
                    <div className="px-4">
                        <h1 className="text-3xl font-semibold">{rows.meta.title}</h1>
                    </div>
                </div>
            </div>
            <div className="container mx-auto">
                <div className="flex flex-wrap items-stretch mt-10">
                    <div className="w-full lg:w-9/12">
                        <div className="flex flex-wrap items-stretch -mt-6 my-4 lg:mb-0">
                            {Object.keys(rows.data)
                            .sort(function (a, b) {
                                return rows.data[a]['fields']['title']['value'].localeCompare(rows.data[b]['fields']['title']['value']);
                            })
                            .map((key) => (
                                <CountryBlock key={key} id={key} type={query.type} {...rows.data[key]} />
                            ))}
                        </div>
                    </div>
                    <div className="w-full lg:w-3/12">
                        <RightBlock />
                    </div>
                </div>
            </div>
        </section>
    ) : (
        <NotFound />
    );
}

TourPlaces.defaultProps = {
    hideLoading: () => {},
    match: {
        url: null,
    },
    location: {
        search: null,
    },
    showLoading: () => {},
};

export default TourPlaces;
