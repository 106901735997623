import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from '@material-ui/core';

import { PageEndpoint } from 'common/Endpoint';

function ServiceButton({ bgcolor, slug, textcolor, title }) {
    const history = useHistory();

    const handleClick = (event) => {
        history.push(PageEndpoint(slug));
        event.preventDefault();
    };

    return (
        <div className="w-full md:w-6/12 mt-6">
            <div className="flex flex-wrap items-stretch service-button">
                <div className="w-full px-4 mr-auto ml-auto">
                    <div
                        className={`relative flex flex-col justify-center bg-${bgcolor} w-full shadow-lg rounded-lg cursor-pointer hover:shadow-md`}
                        // onClick={handleClick}
                    >
                        <div className="text-lg text-white text-center p-2">
                            {/* <Link underline="none" color="initial" className={`text-${textcolor} font-semibold`} href={PageEndpoint(slug)}> */}
                                {title}
                            {/* </Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

ServiceButton.defaultProps = {
    bgcolor: null,
    slug: null,
    textcolor: null,
    title: null,
};

export default ServiceButton;
