import { connect } from 'react-redux';

import BottomBlock from './BottomBlock';

const mapStateToProps = (state) => {
    const {
        Global: {
            blocks: { bottom },
        },
    } = state;

    let banners = [];

    if (bottom && bottom.length > 0) {
        banners = bottom.flatMap(({ banners, ...r }) => banners.map((o) => ({ ...o, ...r })));
    }

    return { banners };
};

export default connect(mapStateToProps, {})(BottomBlock);
