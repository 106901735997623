import ConnectReducers from 'store/ConnectReducers.js';
import * as GLOBAL from 'store/actions/Global';
import { GetCurrentLanguage } from 'common/Language';

const reducers = {
    [GLOBAL.INITIAL_DETAILS]: (state, action) => ({
        ...state,
        about: action.about,
        blocks: action.blocks,
        contact: action.contact,
        catalog_pages: action.catalog_pages,
        hot_offers: action.hot_offers,
        initialized: true,
        language: action.language,
        name: action.name,
        news: action.news,
        offline: action.offline,
        privacy_policy: action.privacy_policy,
        services_pages: action.services_pages,
        special_offers: action.special_offers,
        terms_of_use: action.terms_of_use,
        tourists_pages: action.tourists_pages,
        version: action.version,
    }),
    [GLOBAL.WINDOW_DETAILS]: (state, { isMobile, windowWidth }) => ({
        ...state,
        isMobile,
        windowWidth,
    }),
    [GLOBAL.CHANGE_LANGUAGE]: (state, { language }) => ({
        ...state,
        language,
    }),
    [GLOBAL.SHOW_LOADING]: (state) => ({
        ...state,
        loading: true,
    }),
    [GLOBAL.HIDE_LOADING]: (state) => ({
        ...state,
        loading: false,
    }),
};

export default (
    state = {
        about: null,
        blocks: {},
        contact: null,
        catalog_pages: [],
        hot_offers: [],
        initialized: false,
        isMobile: null,
        language: GetCurrentLanguage(),
        loading: false,
        name: null,
        news: [],
        offline: { status: 0 },
        privacy_policy: null,
        services_pages: [],
        special_offers: [],
        terms_of_use: null,
        tourists_pages: [],
        version: 1,
        windowWidth: null,
    },
    action
) => ConnectReducers(reducers, state, action);
