import React from 'react';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Button, Dialog, DialogContent, DialogTitle, FormControl, IconButton, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CallIcon from '@material-ui/icons/Call';
import CloseIcon from '@material-ui/icons/Close';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';

import { ApiPost } from 'common/Api';
import { Translation } from 'common/Translation';

function Booking({ call, isMobile, parentEl, tour }) {
    const location = useLocation();
    const [data, setData] = React.useState({ booking: !call, tkey: tour, url: location.pathname });
    const [open, setOpen] = React.useState(false);

    const handleOpen = (event) => {
        setOpen(true);
        event.preventDefault();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (name) => (event) => {
        data[name] = event.target.value;

        setData(data);
    };

    const handleSubmit = (event) => {
        if (event.type === 'keydown' && event.key !== 'Enter') {
            return;
        }

        const toastId = toast.loading(Translation({ variable: 'general.loading' }));
        ApiPost('booking', data).then((response) => {
            if (response.status) {
                handleClose();
                toast.success(Translation({ variable: 'general.booking_success' }), {
                    id: toastId,
                });
            } else {
                const errors = response.data?.errors?.name || response.data?.errors?.phone;

                if (errors && errors.length > 0) {
                    toast.dismiss(toastId);

                    errors.map(function (el) {
                        toast.error(el);
                    });
                } else {
                    toast.error(Translation({ variable: 'general.smtg_went_wrong' }), {
                        id: toastId,
                    });
                }
            }
        });
    };

    return (
        <div className={`action-block-${call ? 'callback' : 'booking'}`}>
            <Dialog fullScreen={isMobile} maxWidth="xs" open={open} onClose={handleClose} className={`block-${call ? 'callback' : 'booking'}`}>
                <DialogTitle>
                    {Translation({ variable: call ? 'general.request_header' : 'general.booking_header' })}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        style={{
                            position: 'absolute',
                            right: '0.25rem',
                            top: '0.25rem',
                            color: (theme) => theme.palette.grey[700],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <FormControl margin="dense" className="w-full">
                        <Alert variant="outlined" severity="info" className="mb-8">
                            {Translation({ variable: 'general.booking_info' })}
                        </Alert>
                    </FormControl>
                    <FormControl margin="dense" className="w-full">
                        <TextField
                            className="py-2"
                            variant="outlined"
                            label={Translation({ variable: 'general.enter_name' })}
                            onChange={handleChange('name')}
                            onKeyDown={handleSubmit}
                        />
                    </FormControl>
                    <FormControl margin="dense" className="w-full">
                        <TextField
                            variant="outlined"
                            label={Translation({ variable: 'general.enter_phone' })}
                            onChange={handleChange('phone')}
                            onKeyDown={handleSubmit}
                        />
                    </FormControl>
                    {!call && (
                        <FormControl margin="dense" className="w-full">
                            <TextField
                                multiline
                                rows={4}
                                variant="outlined"
                                label={Translation({ variable: 'general.enter_notes' })}
                                onChange={handleChange('notes')}
                                onKeyDown={handleSubmit}
                            />
                        </FormControl>
                    )}
                    <FormControl margin="dense" className="w-full">
                        <Button color={call ? 'secondary' : 'primary'} variant="contained" onClick={handleSubmit}>
                            {Translation({ variable: call ? 'general.request_now' : 'general.book_now' })}
                        </Button>
                    </FormControl>
                </DialogContent>
            </Dialog>
            {parentEl ? (
                <div onClick={handleOpen}>{parentEl}</div>
            ) : (
                <Button size="small" color="primary" variant="contained" startIcon={call ? <CallIcon /> : <ShoppingCartIcon />} onClick={handleOpen}>
                    {Translation({ variable: call ? 'general.call_now' : 'general.book_now' })}
                </Button>
            )}
        </div>
    );
}

Booking.defaultProps = {
    call: false,
    isMobile: false,
    parentEl: null,
    tour: null,
};

export default Booking;
