import React from 'react';
import { Helmet } from 'react-helmet';

function SeoTags({ description, image, keywords, name, title, url }) {
    return (
        <Helmet encodeSpecialCharacters={true} defaultTitle={name} titleTemplate={`%s / ${name}`}>
            <title>{title}</title>
            <meta name="description" content={description} />
            {keywords && <meta name="keywords" content={keywords} />}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            {image && <meta property="og:image" content={image} />}
            {image && <meta property="og:image:alt" content={title} />}
            {url && <meta property="og:url" content={`${window.location.origin}${url}`} />}
            <meta property="og:site_name" content={name} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            {image && <meta name="twitter:image" content={image} />}
            {image && <meta name="twitter:image:alt" content={title} />}
        </Helmet>
    );
}

SeoTags.defaultProps = {
    description: null,
    image: null,
    keywords: null,
    name: null,
    title: null,
    url: null,
};

export default SeoTags;
