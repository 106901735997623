import { connect } from 'react-redux';

import { hideLoading, showLoading } from 'store/actions/Global';
import NewsList from './NewsList';

const mapDispatchToProps = (dispatch) => ({
    hideLoading: () => hideLoading()(dispatch),
    showLoading: () => showLoading()(dispatch),
});

export default connect(null, mapDispatchToProps)(NewsList);
