import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { Box, CssBaseline, Grid } from '@material-ui/core';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';

import BottomBlock from 'components/BottomBlock';
import Header from 'components/Header';
import Footer from 'components/Footer';
import LazyLoad from 'components/LazyLoad';
import PopupBlock from 'components/PopupBlock';

import Router from './router';
import theme from './theme';
import style from './style';

function Layout({ classes, initialized, isMobile, language, loading, offline }) {
    return initialized ? (
        <ThemeProvider theme={theme}>
            <div className={classes.container}>
                {loading && <LazyLoad />}
                <CssBaseline />
                {offline.status == 1 && (
                    <div className="offline-box">
                        <Box display="flex">
                            <Grid direction="column" justifyContent="center" alignItems="center" spacing={4} container>
                                <Grid item>
                                    <img src={require('assets/img/logo-a.png')} alt={name} />
                                </Grid>
                                <Grid item>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: offline.message || 'Our website is temporarily offline',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                )}
                {offline.status != 1 && (
                    <BrowserRouter>
                        <div className={classes.root}>
                            <Header transparent />
                            <main className={classes.main}>
                                <div className={classes.content}>
                                    <Router language={language} />
                                </div>
                                <BottomBlock />
                            </main>
                            <Footer />
                        </div>
                        <PopupBlock />
                    </BrowserRouter>
                )}
                <Toaster
                    position={isMobile ? 'top-left' : 'bottom-right'}
                    reverseOrder={true}
                    toastOptions={{
                        duration: 5000,
                        className: 'alert-popup',
                    }}
                />
            </div>
        </ThemeProvider>
    ) : (
        <LazyLoad />
    );
}

Layout.defaultProps = {
    classes: {},
    initialized: false,
    isMobile: false,
    language: null,
    loading: false,
    offline: { status: 0 },
};

export default withStyles(style)(Layout);
