export const INITIAL_DETAILS = 'INITIAL_DETAILS';
export const setInitialDetails = (details) => (dispatch) => {
    const { blocks, hot_offers, language, name, news, offline, special_offers, special_pages, translations, version } = details;
    localStorage.setItem('language', language);
    localStorage.setItem('translations', JSON.stringify(translations));

    const filterPages = (pages, category) => {
        return pages.filter(function (page) {
            return page.fields && page.fields.category && page.fields.category.value && page.fields.category.value == category;
        });
    };

    return dispatch({
        about: filterPages(special_pages, 4).shift() || null,
        blocks,
        catalog_pages: filterPages(special_pages, 1),
        contact: filterPages(special_pages, 5).shift() || null,
        hot_offers,
        language,
        name,
        news,
        offline,
        privacy_policy: filterPages(special_pages, 6).shift() || null,
        services_pages: filterPages(special_pages, 3),
        special_offers,
        special_pages,
        terms_of_use: filterPages(special_pages, 7).shift() || null,
        tourists_pages: filterPages(special_pages, 2),
        version,
        type: INITIAL_DETAILS,
    });
};

export const WINDOW_DETAILS = 'WINDOW_DETAILS';
export const setWindowDetails = (details) => (dispatch) => {
    const { isMobile, windowWidth } = details;
    return dispatch({
        isMobile,
        windowWidth,
        type: WINDOW_DETAILS,
    });
};

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const changeLanguage = (iso) => (dispatch) => {
    localStorage.setItem('language', iso);
    return dispatch({
        language: iso,
        type: CHANGE_LANGUAGE,
    });
};

export const SHOW_LOADING = 'SHOW_LOADING';
export const showLoading = () => (dispatch) => {
    return dispatch({
        type: SHOW_LOADING,
    });
};

export const HIDE_LOADING = 'HIDE_LOADING';
export const hideLoading = () => (dispatch) => {
    return dispatch({
        type: HIDE_LOADING,
    });
};
