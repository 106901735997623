import React from 'react';
import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import { Translation } from 'common/Translation';

function ToursFilter({ country, departure, event, handleChange, hot, isMobile, offer, transport, type, values, vid }) {
    const theme = useTheme();
    const [activeSelect, setActiveSelect] = React.useState(null);
    const [show, setShow] = React.useState(false);
    const [parameters, setParameters] = React.useState({});

    const getStyles = (value, array) => {
        return { fontWeight: array.indexOf(value) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium };
    };

    const MenuProps = {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
        getContentAnchorEl: null,
    };

    const handleChangeField = (name) => (event) => {
        const {
            target: { value, checked },
        } = event;

        if (checked !== undefined) {
            parameters[name] = checked ? 1 : 0;
        } else {
            parameters[name] = value;
        }

        setParameters({ ...parameters });
        handleChange(parameters);
    };

    React.useEffect(() => {
        setParameters(values);
    }, [values]);

    return (
        <div className="container mx-auto" onClick={() => setActiveSelect(null)}>
            <div className="flex flex-wrap items-stretch tours-filters">
                {isMobile && (
                    <div className="w-full mx-4 mb-4">
                        <Button variant="outlined" onClick={() => setShow(!show)}>
                            {show ? Translation({ variable: 'tour.labels.hide_filters' }) : Translation({ variable: 'tour.labels.show_filters' })}
                        </Button>
                    </div>
                )}
                {(show || !isMobile) && (
                    <>
                        {type && Object.keys(type).length > 0 && (
                            <FormControl className="w-full lg:w-4/12">
                                <Select
                                    id="tours-type-field"
                                    multiple
                                    displayEmpty
                                    open={activeSelect === 'type'}
                                    onOpen={() => setActiveSelect('type')}
                                    value={parameters.type || []}
                                    onChange={handleChangeField('type')}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return (
                                                <Box>
                                                    <Chip label={Translation({ variable: 'tour.labels.type' })} variant="outlined" />
                                                </Box>
                                            );
                                        }
                                        return (
                                            <Box>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={type[value]} />
                                                ))}
                                            </Box>
                                        );
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    {Object.keys(type).map((key, i) => (
                                        <MenuItem key={type[key]} value={key} style={getStyles(key, parameters.type || [])}>
                                            {type[key]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        {country && Object.keys(country).length > 0 && (
                            <FormControl className="w-full lg:w-4/12">
                                <Select
                                    id="tours-country-field"
                                    multiple
                                    displayEmpty
                                    open={activeSelect === 'country'}
                                    onOpen={() => setActiveSelect('country')}
                                    value={parameters.country || []}
                                    onChange={handleChangeField('country')}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return (
                                                <Box>
                                                    <Chip label={Translation({ variable: 'tour.labels.country' })} variant="outlined" />
                                                </Box>
                                            );
                                        }
                                        return (
                                            <Box>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={country[value]} />
                                                ))}
                                            </Box>
                                        );
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    {Object.keys(country)
                                        .sort(function (a, b) {
                                            return country[a].localeCompare(country[b]);
                                        })
                                        .map((key, i) => (
                                            <MenuItem key={country[key]} value={key} style={getStyles(key, parameters.country || [])}>
                                                {country[key]}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        )}
                        {vid && Object.keys(vid).length > 0 && (
                            <FormControl className="w-full lg:w-4/12">
                                <Select
                                    id="tours-vid-field"
                                    multiple
                                    displayEmpty
                                    open={activeSelect === 'vid'}
                                    onOpen={() => setActiveSelect('vid')}
                                    value={parameters.vid || []}
                                    onChange={handleChangeField('vid')}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return (
                                                <Box>
                                                    <Chip label={Translation({ variable: 'tour.labels.vid' })} variant="outlined" />
                                                </Box>
                                            );
                                        }
                                        return (
                                            <Box>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={vid[value]} />
                                                ))}
                                            </Box>
                                        );
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    {Object.keys(vid).map((key, i) => (
                                        <MenuItem key={vid[key]} value={key} style={getStyles(key, parameters.vid || [])}>
                                            {vid[key]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        {event && Object.keys(event).length > 0 && (
                            <FormControl className="w-full lg:w-4/12">
                                <Select
                                    id="tours-event-field"
                                    multiple
                                    displayEmpty
                                    open={activeSelect === 'event'}
                                    onOpen={() => setActiveSelect('event')}
                                    value={parameters.event || []}
                                    onChange={handleChangeField('event')}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return (
                                                <Box>
                                                    <Chip label={Translation({ variable: 'tour.labels.event' })} variant="outlined" />
                                                </Box>
                                            );
                                        }
                                        return (
                                            <Box>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={event[value]} />
                                                ))}
                                            </Box>
                                        );
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    {Object.keys(event).map((key, i) => (
                                        <MenuItem key={event[key]} value={key} style={getStyles(key, parameters.event || [])}>
                                            {event[key]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        {departure && Object.keys(departure).length > 0 && (
                            <FormControl className="w-full lg:w-4/12">
                                <Select
                                    id="tours-departure-field"
                                    multiple
                                    displayEmpty
                                    open={activeSelect === 'departure'}
                                    onOpen={() => setActiveSelect('departure')}
                                    value={parameters.departure || []}
                                    onChange={handleChangeField('departure')}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return (
                                                <Box>
                                                    <Chip label={Translation({ variable: 'tour.labels.departure' })} variant="outlined" />
                                                </Box>
                                            );
                                        }
                                        return (
                                            <Box>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={departure[value]} />
                                                ))}
                                            </Box>
                                        );
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    {Object.keys(departure)
                                        .sort(function (a, b) {
                                            return departure[a].localeCompare(departure[b]);
                                        })
                                        .map((key, i) => (
                                            <MenuItem key={departure[key]} value={key} style={getStyles(key, parameters.departure || [])}>
                                                {departure[key]}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        )}
                        {transport && Object.keys(transport).length > 0 && (
                            <FormControl className="w-full lg:w-4/12">
                                <Select
                                    id="tours-transport-field"
                                    multiple
                                    displayEmpty
                                    open={activeSelect === 'transport'}
                                    onOpen={() => setActiveSelect('transport')}
                                    value={parameters.transport || []}
                                    onChange={handleChangeField('transport')}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return (
                                                <Box>
                                                    <Chip label={Translation({ variable: 'tour.labels.transport' })} variant="outlined" />
                                                </Box>
                                            );
                                        }
                                        return (
                                            <Box>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={transport[value]} />
                                                ))}
                                            </Box>
                                        );
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    {Object.keys(transport).map((key, i) => (
                                        <MenuItem key={transport[key]} value={key} style={getStyles(key, parameters.transport || [])}>
                                            {transport[key]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        {(hot || offer) && (
                            <FormControl className="w-full lg:w-4/12">
                                <FormGroup>
                                    {offer && (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={parameters.offer == 1 ? true : false}
                                                    color="primary"
                                                    size="small"
                                                    onChange={handleChangeField('offer')}
                                                />
                                            }
                                            label={offer}
                                        />
                                    )}
                                    {hot && (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={parameters.hot == 1 ? true : false}
                                                    color="primary"
                                                    size="small"
                                                    onChange={handleChangeField('hot')}
                                                />
                                            }
                                            label={hot}
                                        />
                                    )}
                                </FormGroup>
                            </FormControl>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

ToursFilter.defaultProps = {
    departure: {},
    event: {},
    handleChange: () => {},
    hot: null,
    offer: null,
    transport: {},
    type: {},
    values: {},
    vid: {},
};

export default ToursFilter;
