import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, MenuItem, OutlinedInput, Select, Tooltip } from '@material-ui/core';
import dateFormat from 'dateformat';

import { TourEndpoint } from 'common/Endpoint';
import { Translation } from 'common/Translation';
import { TrimValueByLength } from 'common/Typography';
import { GetCurrencySymbol } from 'common/Currency';

function TourPreview({ catering, currency, slug, image, fields, occupancy, price, size, type }) {
    const history = useHistory();
    const [tourDate, setTourDate] = useState('');

    useEffect(() => {
        setTourDate(
            type !== 2 && fields.date && fields.date.value?.length > 0
                ? fields.date.value[0]
                : fields.periods && fields.periods.value?.length > 0
                ? fields.periods.value[0].start
                : ''
        );
    }, [fields, type]);

    const handleClick = (event) => {
        if (event.target.name || !event.target?.closest('.tour-preview-block')) {
            return event.preventDefault();
        }

        history.push(TourEndpoint(slug));
        event.preventDefault();
    };

    return (
        <div className={`w-full lg:w-${size}/12 mt-6 tour-preview-block`}>
            <div className="flex flex-wrap items-stretch">
                <div className="w-full px-4 mr-auto ml-auto">
                    <div
                        className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-gray-100 cursor-pointer hover:-mt-4"
                        onClick={handleClick}
                    >
                        <img src={image.url || '/'} className="w-full align-middle rounded-t-lg" alt={fields.title?.value} />
                        <blockquote className="relative p-8 mb-4 min-h-40">
                            <p className="text-xl font-bold leading-relaxed mt-1 mb-4 text-gray-700">{fields.title?.value}</p>
                            {fields.country && (
                                <p className="text-md font-light leading-relaxed mb-4 text-green-600">
                                    {fields.country.value.slice(0, 3).map((country, index) => {
                                        return (
                                            <span className="country-block" key={`${country.fields?.title?.value}-${index}`}>
                                                {country.fields?.title?.value}
                                                {fields.country.value[index + 1] && index + 1 < 3 ? ',' : ''}
                                            </span>
                                        );
                                    })}
                                    {fields.country.value.length > 3 ? '...' : ''}
                                </p>
                            )}
                            <p className="text-md font-light leading-relaxed mb-4 text-gray-700">
                                <i className={`fas fa-${type === 2 ? 'plane' : 'route'}`}></i> {TrimValueByLength(fields.itinerary?.value, 100)}
                            </p>
                            {fields.periods && fields.periods.value?.length > 0 && (
                                <div className="text-md font-light leading-relaxed mb-4 text-gray-700" id="tour-period-block">
                                    <i className="fas fa-calendar-alt"></i>
                                    <Select
                                        id="tour-period-field"
                                        name="tour-period"
                                        className="tour-period"
                                        value={tourDate}
                                        input={<OutlinedInput />}
                                        onChange={(event) => {
                                            setTourDate(event.target.value);
                                        }}
                                    >
                                        {fields.periods.value.map((date) => (
                                            <MenuItem key={date.start} value={date.start}>
                                                {dateFormat(date.start, 'dd.mm.yyyy')} - {dateFormat(date.end, 'dd.mm.yyyy')}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            )}
                            {type !== 2 && fields.date && fields.date.value?.length > 0 && (
                                <div className="text-md font-light leading-relaxed mb-4 text-gray-700" id="tour-date-block">
                                    <i className="fas fa-calendar-alt"></i>
                                    <Select
                                        id="tour-date-field"
                                        name="tour-date"
                                        className="tour-date"
                                        value={tourDate}
                                        input={<OutlinedInput />}
                                        onChange={(event) => {
                                            setTourDate(event.target.value);
                                        }}
                                    >
                                        {fields.date.value.map((date) => (
                                            <MenuItem key={date} value={date}>
                                                {dateFormat(date, 'dd.mm.yyyy')}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {/* <span className="mr-2">
                                        <i className="fas fa-calendar-alt"></i> {dateFormat(fields.date.value[0], 'dd.mm.yyyy')}
                                    </span>
                                    {fields.date.value?.length > 1 && (
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            style={{
                                                fontSize: '0.75rem',
                                                padding: '0 6px',
                                                textTransform: 'none',
                                                marginTop: '-4px'
                                            }}
                                        >
                                            Інші дати
                                        </Button>
                                    )} */}
                                </div>
                            )}
                            <p className="text-md font-light leading-relaxed mb-12 text-gray-700">
                                <i className="fas fa-circle-notch"></i> {TrimValueByLength(fields.duration?.value, 20)}
                            </p>
                            <div className="absolute w-full bottom-0 left-0 px-4">
                                {/* <div className="absolute w-full -top-50-px left-0 px-4">
                                    {fields.vid && (
                                        <Tooltip title={Object.values(fields.vid.value).join(', ')}>
                                            <span 
                                                className="text-xs font-semibold inline-block p-2 uppercase rounded text-gray-700 bg-green-200 mr-1"
                                            >
                                                {TrimValueByLength(Object.values(fields.vid.value).join(', '), 16)}
                                            </span>
                                        </Tooltip>
                                    )}
                                    {catering && (
                                        <Tooltip title={catering}>
                                            <span className="text-xs font-semibold inline-block p-2 uppercase rounded text-gray-700 bg-blue-200 mr-1">
                                                {TrimValueByLength(catering, 3)}
                                            </span>
                                        </Tooltip>
                                    )}
                                    {occupancy && (
                                        <Tooltip title={occupancy}>
                                            <span className="text-xs font-semibold inline-block p-2 uppercase rounded text-gray-700 bg-red-200 mr-1">
                                                {TrimValueByLength(occupancy, 10)}
                                            </span>
                                        </Tooltip>
                                    )}
                                </div> */}
                                <div className={`${type === 2 ? 'bg-green-600' : 'bg-gray-200'} -mx-4 -mb-4 p-4 rounded-lg rounded-t-0 type-${type}`}>
                                    <span className="text-xl font-bold text-red-600 float-right">
                                        <i className="text-base fas fa-tag"></i>{' '}
                                        {fields.pfrom?.value == 1 && Translation({ variable: 'tour.labels.price_from' })} {price}{' '}
                                        {GetCurrencySymbol(currency.code || null)}
                                    </span>
                                    <Button color="primary" href={TourEndpoint(slug)}>
                                        {Translation({ variable: 'general.more' })}
                                    </Button>
                                </div>
                            </div>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>
    );
}

TourPreview.defaultProps = {
    catering: null,
    currency: {},
    slug: null,
    image: {},
    fields: {
        title: {
            value: null,
        },
        itinerary: {
            value: null,
        },
        duration: {
            value: null,
        },
        pfrom: {
            value: 0,
        },
        periods: {
            value: [],
        },
    },
    type: 1,
    occupancy: null,
    price: 0,
    size: 4,
};

export default TourPreview;
