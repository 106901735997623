import axios from 'axios';

import { GetCurrentLanguage } from 'common/Language';

export const ApiGet = (method) =>
    axios
        .get(`${process.env.REACT_APP_API_ENDPOINT}/api/${method}`, {
            headers: {
                Accept: 'application/json',
                'Accept-Language': GetCurrentLanguage(),
            },
        })
        .then(function (response) {
            return {
                ...response,
                status: true,
            };
        })
        .catch(function (error) {
            return {
                ...error.response,
                status: false,
            };
        });

export const ApiPost = (method, parameters) =>
    axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/api/${method}`, parameters, {
            headers: {
                Accept: 'application/json',
                'Accept-Language': GetCurrentLanguage(),
            },
        })
        .then(function (response) {
            return {
                ...response,
                status: true,
            };
        })
        .catch(function (error) {
            return {
                ...error.response,
                status: false,
            };
        });

export const ApiPut = (method, parameters) =>
    axios
        .put(`${process.env.REACT_APP_API_ENDPOINT}/api/${method}`, parameters, {
            headers: {
                Accept: 'application/json',
                'Accept-Language': GetCurrentLanguage(),
            },
        })
        .then(function (response) {
            return {
                ...response,
                status: true,
            };
        })
        .catch(function (error) {
            return {
                ...error.response,
                status: false,
            };
        });

export const ApiDelete = (method, parameters) =>
    axios
        .delete(`${process.env.REACT_APP_API_ENDPOINT}/api/${method}`, {
            headers: {
                Accept: 'application/json',
                'Accept-Language': GetCurrentLanguage(),
            },
            data: parameters,
        })
        .then(function (response) {
            return {
                ...response,
                status: true,
            };
        })
        .catch(function (error) {
            return {
                ...error.response,
                status: false,
            };
        });
