import { connect } from 'react-redux';

import Footer from './Footer';

const mapStateToProps = (state) => {
    const {
        Global: { catalog_pages, name, privacy_policy, terms_of_use, tourists_pages, version },
    } = state;
    return { catalog_pages, name, privacy_policy, terms_of_use, tourists_pages, version };
};

export default connect(mapStateToProps, {})(Footer);
