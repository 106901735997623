import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from '@material-ui/core';
import ReactPlayer from 'react-player';

import { GetEndpointByLanguage } from 'common/Endpoint';

function Banner({ file, handleParent, index, isSelected, title, type, url }) {
    const history = useHistory();

    const handleClick = (event) => {
        handleParent(index, isSelected, file);
        if (url !== null) {
            history.push(GetEndpointByLanguage(url));
        }
        event.preventDefault();
    };

    return (
        <Link className="item" href={url !== null ? GetEndpointByLanguage(url) : '#'} onClick={handleClick}>
            {type === 2 ? (
                <>
                    <div className="overall"></div>
                    <ReactPlayer
                        width="100%"
                        height="auto"
                        url={file}
                        playing={isSelected}
                        muted
                        loop
                        config={{
                            youtube: {
                                playerVars: { disablekb: 1 },
                            },
                        }}
                    />
                </>
            ) : (
                <div style={{ width: '100%', height: 'auto' }}>
                    <img src={file} alt={title} />
                </div>
            )}
        </Link>
    );
}

Banner.defaultProps = {
    file: null,
    handleParent: () => {},
    index: 0,
    isSelected: false,
    title: null,
    type: 1,
    url: null,
};

export default Banner;
