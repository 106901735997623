import React from 'react';
import { Card, CardContent, Tooltip } from '@material-ui/core';

function PartnerBlock({ title, image }) {
    return (
        <div className="w-full md:w-3/12 mt-6">
            <div className="w-full px-4">
                <Card className="partner-block">
                    <Tooltip title={title}>
                        <CardContent>
                            <img src={require(`assets/img/partners/${image}.png`)} alt={title} />
                        </CardContent>
                    </Tooltip>
                </Card>
            </div>
        </div>
    );
}

PartnerBlock.defaultProps = {
    title: null,
    image: null,
};

export default PartnerBlock;
