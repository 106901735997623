import { connect } from 'react-redux';

import RightBlock from './RightBlock';

const mapStateToProps = (state) => {
    const {
        Global: {
            blocks: { right },
        },
    } = state;

    let banners = [];

    if (right && right.length > 0) {
        banners = right.flatMap(({ banners, ...r }) => banners.map((o) => ({ ...o, ...r })));
    }

    return { banners };
};

export default connect(mapStateToProps, {})(RightBlock);
