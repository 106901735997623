export const LanguageList = ['uk']; // 'en', 'ru', 

export const GetCurrentLanguage = (iso = null) => {
    if (iso === null) {
        iso = localStorage.getItem('language');
    }

    if (iso && iso.length === 2 && LanguageList.includes(iso)) {
        return iso;
    }

    return process.env?.REACT_APP_DEFAULT_LANGUAGE || 'uk';
};
