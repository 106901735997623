export const GetCurrencySymbol = (code) => {
    switch (code.toLowerCase()) {
        case 'eur':
            return '€';
        case 'usd':
            return '$';
        default:
            return 'грн'; //'₴';
    }
};
