import React from 'react';
import { Drawer, List, ListItem, ListItemText, Link } from '@material-ui/core';
import { HandleMaskPhoneNumber } from 'common/Phone';

function Social({ mode }) {
    const [phonesMenu, setPhonesMenu] = React.useState(null);

    const handleOpenPhonesMenu = (event) => {
        setPhonesMenu(event.currentTarget);
        event.preventDefault();
    };

    const handleClosePhonesMenu = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setPhonesMenu(null);
    };

    return (
        <ul className={`flex flex-col lg:flex-row list-none social social-${mode}`}>
            <li className="flex items-center">
                <Link
                    onClick={handleOpenPhonesMenu}
                    color="inherit"
                    underline="none"
                    className="w-full hover:text-red-600 px-3 py-4 lg:py-2 flex items-center text-green-600 text-md uppercase font-bold"
                    href="tel:+380685479621"
                >
                    <i className="fas fa-phone text-lg leading-lg mb-1" />
                    <span className="text-xs inline-block ml-2 mt-1">{HandleMaskPhoneNumber('+380685479621')}</span>
                </Link>
                <Drawer anchor="top" open={Boolean(phonesMenu)} onClose={handleClosePhonesMenu}>
                    <div onClick={handleClosePhonesMenu} onKeyDown={handleClosePhonesMenu}>
                        <List>
                            {[
                                { phone: '+380685479621', name: 'Київстар' },
                                { phone: '+380631892240', name: 'lifecell' },
                                { phone: '+380964886095', name: 'Київстар' },
                                { phone: '+380964886095', name: 'Viber' },
                                { phone: '+380685479621', name: 'Viber' },
                            ].map((item, index) => (
                                <Link
                                    key={index}
                                    color="secondary"
                                    underline="none"
                                    href={item.name === 'Viber' ? `viber://chat?number=${item.phone}` : `tel:${item.phone}`}
                                >
                                    <ListItem button>
                                        <ListItemText primary={HandleMaskPhoneNumber(item.phone)} secondary={item.name} />
                                    </ListItem>
                                </Link>
                            ))}
                        </List>
                    </div>
                </Drawer>
            </li>
            <li className="flex items-center">
                <Link
                    color="secondary"
                    underline="none"
                    className="w-full hover:text-red-600 px-3 py-4 lg:py-2 flex items-center text-md uppercase font-bold"
                    href="https://invite.viber.com/?g2=AQB337sXp%2F8MFEhJDF1of5qDzyAwHiUZXmk8hZipazq7gO3L95GfTPt6J9qQwhlj&lang=uk"
                    target="_blank"
                >
                    <i className="fab fa-viber text-lg leading-lg" />
                    <span className="lg:hidden inline-block ml-2">Viber</span>
                </Link>
            </li>
            <li className="flex items-center">
                <Link
                    color="secondary"
                    underline="none"
                    className="w-full hover:text-red-600 px-3 py-4 lg:py-2 flex items-center text-md uppercase font-bold"
                    href="tg://resolve?domain=lovetravelvn"
                    target="_blank"
                >
                    <i className="fab fa-telegram text-lg leading-lg" />
                    <span className="lg:hidden inline-block ml-2">Telegram</span>
                </Link>
            </li>
            <li className="flex items-center">
                <Link
                    color="secondary"
                    underline="none"
                    className="w-full hover:text-red-600 px-3 py-4 lg:py-2 flex items-center text-md uppercase font-bold"
                    href="https://www.instagram.com/love_travel_vn"
                    target="_blank"
                >
                    <i className="fab fa-instagram text-lg leading-lg" />
                    <span className="lg:hidden inline-block ml-2">Instagram</span>
                </Link>
            </li>
            <li className="flex items-center">
                <Link
                    color="secondary"
                    underline="none"
                    className="w-full hover:text-red-600 px-3 py-4 lg:py-2 flex items-center text-md uppercase font-bold"
                    href="https://www.facebook.com/LoveTravel.agent"
                    target="_blank"
                >
                    <i className="fab fa-facebook text-lg leading-lg" />
                    <span className="lg:hidden inline-block ml-2">Facebook</span>
                </Link>
            </li>
        </ul>
    );
}

Social.defaultProps = {
    mode: 'lg',
};

export default Social;
