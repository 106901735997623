import React from 'react';

import { ApiGet } from 'common/Api';
import { SetInnerHTML } from 'common/Typography';
import NotFound from 'components/NotFound';
import RightBlock from 'components/RightBlock';
import SeoTags from 'components/SeoTags';

function PageOverview({
    hideLoading,
    match: {
        params: { slug },
        url,
    },
    showLoading,
}) {
    const [row, setRow] = React.useState(null);

    React.useEffect(() => {
        showLoading();
        ApiGet(`page/${slug}`).then((response) => {
            hideLoading();
            if (response.status) {
                setRow(response.data);
            }
        });
    }, [slug]);

    return row && row.data ? (
        <section className="relative">
            <SeoTags title={row.meta.title} description={row.meta.description} url={url} />
            <div className="py-10 bg-gray-300">
                <div className="container mx-auto">
                    <div className="px-4">
                        <h1 className="text-3xl font-semibold">{row.data.fields.header.value}</h1>
                    </div>
                </div>
            </div>
            <div className="container mx-auto">
                <div className="flex flex-wrap items-stretch mt-10">
                    <div className="w-full lg:w-9/12">
                        <div className="text-md font-light leading-relaxed text-description-font px-4 mb-4">
                            {SetInnerHTML(row.data.fields.content.value)}
                        </div>
                    </div>
                    <div className="w-full lg:w-3/12">
                        <RightBlock />
                    </div>
                </div>
            </div>
        </section>
    ) : (
        <NotFound />
    );
}

PageOverview.defaultProps = {
    hideLoading: () => {},
    match: {
        params: { slug: null },
        url: null,
    },
    showLoading: () => {},
};

export default PageOverview;
