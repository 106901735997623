import React from 'react';
import { Card, CardContent, CardMedia, Link, Tooltip, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { TourEndpoint } from 'common/Endpoint';

function CountryBlock({ id, fields, type }) {
    const history = useHistory();

    const handleShowUrl = (pathname, query) => (event) => {
        history.push({
            pathname,
            search: Stringify(query),
        });
        event.preventDefault();
    };

    return (
        <div className="w-full md:w-4/12 mt-6">
            <div className="w-full px-4">
                <Card className="place-country-block">
                    <Link
                        underline="none"
                        onClick={handleShowUrl(TourEndpoint(null), { country: [id], type, hasbeenchanged: true })}
                        href={TourEndpoint(null, { country: [id], type })}
                    >
                        <CardMedia component="img" image={fields.cover?.value?.url} alt={fields.title?.value} />
                        <CardContent className="bg-gray-200 font-bold text-gray-700">
                            <Typography variant="subtitle2" component="div">
                                {fields.title?.value}
                            </Typography>
                        </CardContent>
                    </Link>
                </Card>
            </div>
        </div>
    );
}

CountryBlock.defaultProps = {
    id: null,
    fields: null,
    type: null,
};

export default CountryBlock;
