import React from 'react';
import { useCookies } from 'react-cookie';
import { Drawer, Button } from '@material-ui/core';

import { Translation } from 'common/Translation';

function CookieConsent() {
    const [cookies, setCookie] = useCookies(['accept_cookies']);

    function onAccept() {
        setCookie('accept_cookies', true, { path: '/' });
    }

    return (
        <Drawer anchor="bottom" open={Boolean(!cookies.accept_cookies)}>
            <div className="container mx-auto">
                <div className="flex flex-wrap items-stretch p-8 cookie_info">
                    <div className="w-full sm:w-9/12">{Translation({ variable: 'general.cookies_info', values: {}, html: true })}</div>
                    <div className="w-full sm:w-3/12">
                        <Button variant="outlined" color="primary" onClick={onAccept}>
                            {Translation({ variable: 'general.accept_cookies' })}
                        </Button>
                    </div>
                </div>
            </div>
        </Drawer>
    );
}

export default CookieConsent;
