import React from 'react';
import { Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { PageEndpoint } from 'common/Endpoint';
import { HandleMaskPhoneNumber } from 'common/Phone';
import { Translation } from 'common/Translation';
import CookieConsent from 'components/CookieConsent';
import Subscription from 'components/Subscription';

function Footer({ catalog_pages, name, privacy_policy, terms_of_use, tourists_pages, version }) {
    const history = useHistory();
    const ref = React.useRef();

    const handleShowUrl = (url) => (event) => {
        history.push(url);
        event.preventDefault();
    };

    return (
        <>
            <footer className="relative pt-10 bg-gray-900">
                <CookieConsent />
                <div className="container mx-auto">
                    <div className="flex flex-wrap items-stretch text-white lg:mb-12">
                        <div className="w-full lg:w-6/12 px-4 mb-6">
                            <Link className="max-w-200-px block mb-5" onClick={handleShowUrl('/')} href="/">
                                <img src={require('assets/img/logo-b.png')} alt={name} />
                            </Link>
                            <Link
                                className="block"
                                color="inherit"
                                underline="none"
                                target="_blank"
                                href="//www.google.com/maps/place/Love+Travel/@49.233489,28.466683,15z/data=!4m5!3m4!1s0x0:0xd6e54f29217b78ea!8m2!3d49.233489!4d28.466683"
                            >
                                <i className="far fa-map mr-2" />
                                {Translation({ variable: 'general.address' })}
                            </Link>
                            <Link className="block" color="inherit" underline="none" href="tel:+380985196911">
                                <i className="fas fa-phone-alt mr-2" />
                                {HandleMaskPhoneNumber('+380985196911')}
                            </Link>
                            <Link className="block" color="inherit" underline="none" target="_blank" href="mailto:info@love-tur.com">
                                <i className="far fa-envelope-open mr-2"></i>
                                info@love-tur.com
                            </Link>
                            <Link
                                className="block"
                                color="inherit"
                                underline="none"
                                target="_blank"
                                href="//www.google.com/maps/dir//49.2334882,28.4667274/@49.231438,28.4569512,15z"
                            >
                                <i className="fas fa-map-marker-alt mr-2" />
                                49.2334882,28.4667274
                            </Link>
                            <div className="whrs mt-5">
                                <span className="block">
                                    <i className="icon-block fas fa-clock mr-2" />
                                    пн-пт: 09:00 - 19:00
                                </span>
                                <span className="block">
                                    <i className="icon-block mr-2" />
                                    сб: 10:00 - 17:00
                                </span>
                                <span className="block">
                                    <i className="icon-block mr-2" />
                                    нд: вихідний
                                </span>
                            </div>
                        </div>
                        <div className="w-full lg:w-3/12 px-4 mb-6">
                            <div className="uppercase text-gray-400 font-semibold mb-4">{Translation({ variable: 'general.catalog' })}</div>
                            {catalog_pages && (
                                <ul className="list-unstyled">
                                    {catalog_pages.map((page, index) => (
                                        <li key={index}>
                                            <Link
                                                color="inherit"
                                                underline="none"
                                                onClick={handleShowUrl(PageEndpoint(page.slug))}
                                                href={PageEndpoint(page.slug)}
                                            >
                                                {page.fields.header.value}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div className="w-full lg:w-3/12 px-4 mb-6">
                            <div className="uppercase text-gray-400 font-semibold mb-4">{Translation({ variable: 'general.for_customers' })}</div>
                            {tourists_pages && (
                                <ul className="list-unstyled">
                                    {tourists_pages.map((page, index) => (
                                        <li key={index}>
                                            <Link
                                                color="inherit"
                                                underline="none"
                                                onClick={handleShowUrl(PageEndpoint(page.slug))}
                                                href={PageEndpoint(page.slug)}
                                            >
                                                {page.fields.header.value}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-wrap items-stretch items-center text-white">
                        <div className="w-full lg:w-7/12 px-4 mb-6">
                            <div className="text-white">&copy; 2009-{new Date().getUTCFullYear()} {name}</div>
                            <div className="text-gray-600 text-xs">Version: 4.1.3 <span className="text-gray-900">/ {version}</span></div>
                            <div className="flex flex-wrap items-stretch mt-4">
                                {privacy_policy && (
                                    <div className="inline-block mb-2 mr-4">
                                        <Link
                                            color="inherit"
                                            underline="none"
                                            className="text-xs"
                                            onClick={handleShowUrl(PageEndpoint(privacy_policy.slug))}
                                            href={PageEndpoint(privacy_policy.slug)}
                                        >
                                            {Translation({ variable: 'general.privacy_policy' })}
                                        </Link>
                                    </div>
                                )}
                                {terms_of_use && (
                                    <div className="inline-block mb-2 mr-4">
                                        <Link
                                            color="inherit"
                                            underline="none"
                                            className="text-xs"
                                            onClick={handleShowUrl(PageEndpoint(terms_of_use.slug))}
                                            href={PageEndpoint(terms_of_use.slug)}
                                        >
                                            {Translation({ variable: 'general.terms_of_use' })}
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="w-full lg:w-5/12 px-4 mb-6">
                            <Subscription />
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

Footer.defaultProps = {
    catalog_pages: [
        {
            slug: null,
            fields: {
                header: {
                    name: null,
                    value: null,
                },
            },
        },
    ],
    name: null,
    privacy_policy: {
        slug: null,
    },
    terms_of_use: {
        slug: null,
    },
    tourists_pages: [
        {
            slug: null,
            fields: {
                header: {
                    name: null,
                    value: null,
                },
            },
        },
    ],
    version: 1,
};

export default Footer;
