import React from 'react';
import { useHistory } from 'react-router-dom';
import { Pagination } from '@material-ui/lab';

import { ApiGet } from 'common/Api';
import { ParseUrl, NewsEndpoint } from 'common/Endpoint';
import { Translation } from 'common/Translation';
import NewsPreview from 'components/NewsPreview';
import NotFound from 'components/NotFound';
import RightBlock from 'components/RightBlock';
import SeoTags from 'components/SeoTags';

function NewsList({ hideLoading, match: { url }, location: { search }, showLoading }) {
    const history = useHistory();
    const { query } = ParseUrl(search);
    const [rows, setRows] = React.useState(null);
    const [total, setTotal] = React.useState(1);
    const [parameters, setParameters] = React.useState({
        page: query.page || 1,
    });

    const handleChangePage = (event, value) => {
        setParameters({ ...parameters, page: value });
        event.preventDefault();
    };

    React.useEffect(() => {
        const endpoint = NewsEndpoint(null, parameters);

        showLoading();
        history.push(endpoint);

        ApiGet(endpoint.substring(4)).then((response) => {
            hideLoading();
            if (response.status) {
                setTotal(response.data.meta.pages);
                setRows(response.data);
            }
        });
    }, [parameters]);

    return rows && rows.data ? (
        <section className="relative">
            <SeoTags title={rows.meta.title} description={rows.meta.description} url={url} />
            <div className="py-10 bg-gray-300">
                <div className="container mx-auto">
                    <div className="px-4">
                        <h1 className="text-3xl font-semibold">{Translation({ variable: 'general.last_news' })}</h1>
                    </div>
                </div>
            </div>
            <div className="container mx-auto">
                <div className="flex flex-wrap items-stretch mt-10">
                    <div className="w-full lg:w-9/12">
                        <div className="flex flex-wrap items-stretch -mt-6">
                            {rows.data.map((item, key) => (
                                <NewsPreview key={key} size="6" {...item} />
                            ))}
                        </div>
                        <div className="pagination text-center px-4 my-4 lg:mt-8 lg:mb-0">
                            <Pagination count={parseInt(total)} page={parseInt(parameters.page || 1)} onChange={handleChangePage} color="primary" />
                        </div>
                    </div>
                    <div className="w-full lg:w-3/12">
                        <RightBlock />
                    </div>
                </div>
            </div>
        </section>
    ) : (
        <NotFound />
    );
}

NewsList.defaultProps = {
    hideLoading: () => {},
    match: {
        url: null,
    },
    location: {
        search: null,
    },
    showLoading: () => {},
};

export default NewsList;
