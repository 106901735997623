import { connect } from 'react-redux';

import Layout from './Layout';

const mapStateToProps = (state) => {
    const {
        Global: { initialized, isMobile, language, loading, offline },
    } = state;

    return { initialized, isMobile, language, loading, offline };
};

export default connect(mapStateToProps, {})(Layout);
