import React, { useRef } from 'react';
import toast from 'react-hot-toast';

import { ApiPost } from 'common/Api';
import { Translation } from 'common/Translation';

function Subscription({ type, view, callback }) {
    const textRef = useRef();

    const handleSubmit = (event) => {
        if (event.type === 'keydown' && event.key !== 'Enter') {
            return;
        }

        const toastId = toast.loading(Translation({ variable: 'general.loading' }));
        ApiPost('subscribe', { [type]: textRef.current.value }).then((response) => {
            if (response.status) {
                toast.success(Translation({ variable: 'general.subscribed_success' }), {
                    id: toastId,
                });

                localStorage.setItem('subscribed', response.config.data);
            } else {
                const errors = response.data?.errors;

                if (errors[type] && errors[type].length > 0) {
                    toast.dismiss(toastId);

                    errors[type].map(function (el) {
                        toast.error(el);
                    });
                } else {
                    toast.error(Translation({ variable: 'general.smtg_went_wrong' }), {
                        id: toastId,
                    });
                }
            }

            callback(response);
        });
    };

    return (
        <div className={'subscription ' + view}>
            {view == 'footer' && <h5 className="mt-0 mb-2 text-white">{Translation({ variable: 'general.subscribe_to_newsletter' })}</h5>}
            {type == 'email' && (
                <input
                    type="email"
                    ref={textRef}
                    className={
                        (view == 'footer' ? 'w-6/12' : 'w-full lg:w-6/12') +
                        ' px-3 py-3 placeholder-gray-400 text-gray-700 bg-white focus:outline-none'
                    }
                    placeholder={Translation({ variable: 'general.enter_email' })}
                    onKeyDown={handleSubmit}
                />
            )}
            {type == 'phone' && (
                <input
                    type="tel"
                    ref={textRef}
                    className={
                        (view == 'footer' ? 'w-6/12' : 'w-full lg:w-6/12') +
                        ' px-3 py-3 placeholder-gray-400 text-gray-700 bg-white focus:outline-none'
                    }
                    placeholder={Translation({ variable: 'general.enter_phone' })}
                    onKeyDown={handleSubmit}
                />
            )}
            <button
                onClick={handleSubmit}
                className={
                    (view == 'footer' ? '' : 'w-full lg:w-6/12 ') +
                    'bg-red-600 text-white active:bg-red-700 px-6 py-3 outline-none capitalize focus:outline-none'
                }
                type="button"
            >
                {Translation({ variable: 'general.subscribe' })}
            </button>
        </div>
    );
}

Subscription.defaultProps = {
    type: 'phone',
    view: 'footer',
    callback: () => {},
};

export default Subscription;
