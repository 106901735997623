import { GetCurrentLanguage } from 'common/Language';

export const GetEmbedMapCode = ({ child, parent1, parent2, language }) => {
    let query = [child];

    if (parent1) {
        query.push(parent1);
    }

    if (parent2) {
        query.push(parent2);
    }

    return (
        <iframe
            width="100%"
            height="400"
            loading="lazy"
            style={{
                border: 0,
            }}
            allowFullScreen
            src={`https://www.google.com/maps/embed/v1/place?language=${GetCurrentLanguage()}&q=${encodeURIComponent(query.join(' '))}&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8`}
        />
    );
};
