import { connect } from 'react-redux';

import Home from './Home';

const mapStateToProps = (state) => {
    const {
        Global: {
            blocks: { slider },
            hot_offers,
            news,
            services_pages,
            special_offers,
        },
    } = state;

    const banners = slider ? slider.flatMap(({ banners, ...r }) => banners.map((o) => ({ ...o, ...r }))) : [];

    return { banners, hot_offers, news, services_pages, special_offers };
};

export default connect(mapStateToProps, {})(Home);
