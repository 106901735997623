import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/style/fonts.css';
import 'assets/style/normalize.css';
import 'assets/style/style.scss';

export default {
    container: {
        display: 'flex',
        minHeight: '100vh',
    },
    root: {
        flex: 1,
        display: 'flex',
        maxWidth: '100vw',
        flexDirection: 'column',
    },
    main: {
        flex: 1,
        background: '#fff',
        overflow: 'hidden',
        paddingBottom: '5rem'
    },
    content: {},
};
