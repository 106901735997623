import React from 'react';
import { Grid } from '@material-ui/core';
import dateFormat from 'dateformat';

import { ApiGet } from 'common/Api';
import { NewsEndpoint } from 'common/Endpoint';
import { SetInnerHTML } from 'common/Typography';
import NotFound from 'components/NotFound';
import RightBlock from 'components/RightBlock';
import SeoTags from 'components/SeoTags';

function NewsOverview({
    hideLoading,
    match: {
        params: { slug },
        url,
    },
    showLoading,
}) {
    const [row, setRow] = React.useState(null);

    React.useEffect(() => {
        const endpoint = NewsEndpoint(slug);

        showLoading();

        ApiGet(endpoint.substring(4)).then((response) => {
            hideLoading();
            if (response.status) {
                setRow(response.data);
            }
        });
    }, [slug]);

    return row && row.data ? (
        <section className="relative">
            <SeoTags title={row.meta.title} description={row.meta.description} image={row.meta.image} url={url} />
            <div className="py-10 bg-gray-300">
                <div className="container mx-auto">
                    <div className="px-4">
                        <h1 className="text-3xl font-semibold">{row.data.title}</h1>
                    </div>
                </div>
            </div>
            <div className="container mx-auto">
                <div className="flex flex-wrap items-stretch mt-10">
                    <div className="w-full lg:w-9/12">
                        <div className="text-md font-light leading-relaxed px-4 mb-4">
                            <Grid container alignItems="stretch" spacing={2}>
                                <Grid item xs={12}>
                                    <img src={row.data.image.url} alt={row.data.title} />
                                </Grid>
                                <Grid item xs={12}>
                                    <span className="text-xs">
                                        <i className="text-base fas fa-calendar"></i> {dateFormat(row.data.date, 'dd.mm.yyyy HH:MM')}
                                    </span>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="text-description-font">{SetInnerHTML(row.data.body)}</div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div className="w-full lg:w-3/12">
                        <RightBlock />
                    </div>
                </div>
            </div>
        </section>
    ) : (
        <NotFound />
    );
}

NewsOverview.defaultProps = {
    hideLoading: () => {},
    match: {
        params: { slug: null },
        url: null,
    },
    showLoading: () => {},
};

export default NewsOverview;
