import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { createTheme } from '@material-ui/core/styles';

const breakpoints = createBreakpoints({});

const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            light: '#f04332',
            main: '#e04332',
            dark: '#d04332',
            contrastText: '#fff',
        },
        secondary: {
            light: '#494949',
            main: '#222',
            dark: '#000',
            contrastText: '#fff',
        },
        warning: {
            light: '#ffe169',
            main: '#ffaf37',
            dark: '#ddaf37',
            contrastText: '#222',
        },
        error: {
            light: '#ff5030',
            main: '#d10000',
            dark: '#970000',
            contrastText: '#fff',
        },
        success: {
            light: '#81c784',
            main: '#4caf50',
            dark: '#388e3c',
            contrastText: '#fff',
        },
        info: {
            light: '#fff',
            main: '#d0e5fe',
            dark: '#87b7f1',
            contrastText: '#222',
        },
        text: {
            primary: '#222',
            secondary: '#e04332',
            disabled: '#494949',
            hint: '#000',
        },
    },
    overrides: {
        MuiButton: {
            contained: {
                color: '#fff',
                backgroundColor: '#ffaf37',
                '&:hover': {
                    backgroundColor: '#ddaf37',
                    '@media (hover: none)': {
                        backgroundColor: '#ffaf37',
                    },
                },
                '&:focus': {
                    outline: 'none',
                },
            },
            outlined: {
                color: '#ffaf37',
                borderColor: '#ffaf37',
                backgroundColor: 'unset',
                '&:hover': {
                    borderColor: '#ddaf37',
                    backgroundColor: '#ffe1690a',
                    '@media (hover: none)': {
                        borderColor: '#ffaf37',
                        backgroundColor: 'unset',
                    },
                },
                '&:focus': {
                    outline: 'none',
                },
            },
        },
        MuiDrawer: {
            paperAnchorRight: {
                width: '75%',
                [breakpoints.up('md')]: {
                    width: '35%',
                },
            },
            paperAnchorLeft: {
                width: '75%',
                [breakpoints.up('md')]: {
                    width: '35%',
                },
            },
        },
        MuiDialogTitle: {
            root: {
                padding: '2rem 2.5rem 0',
            },
        },
        MuiDialogContent: {
            root: {
                padding: '2rem 2.5rem',
                '&:first-child': {
                    paddingTop: '2rem',
                },
            },
        },
        MuiPagination: {
            root: {
                display: 'inline-block',
            },
        },
        MuiPaginationItem: {
            page: {
                '&:focus': {
                    outline: 'none',
                },
            },
        },
    },
    typography: {
        fontFamily: ['"ProstoOne"', '"Helvetica"', '"Arial"', 'sans-serif'].join(','),
    },
});

export default theme;
