import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import BaseCarousel from 'components/BaseCarousel';

function PlacePhotos({ title, cover, photos, windowWidth }) {
    const [selected, setSelected] = React.useState(0);
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className="place-photos-block">
            <Dialog fullScreen open={open} onClose={handleClose} className="photos-fullscreen-block">
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        style={{
                            position: 'absolute',
                            right: '0.25rem',
                            top: '0.25rem',
                            color: (theme) => theme.palette.grey[700],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <BaseCarousel
                        files={[cover].concat(photos).map(function (item) {
                            return {
                                title,
                                type: 1,
                                file: item?.url,
                            };
                        })}
                        selectedItem={selected}
                        showThumbs={windowWidth >= 1024}
                    />
                </DialogContent>
            </Dialog>
            <BaseCarousel
                dynamic={true}
                files={[cover].concat(photos).map(function (item) {
                    return {
                        title,
                        type: 1,
                        file: item?.url,
                        handleParent: (index, isSelected) => {
                            if (isSelected) {
                                setSelected(index);
                                setOpen(true);
                            }
                        },
                    };
                })}
                showThumbs={true}
            />
        </div>
    );
}

PlacePhotos.defaultProps = {
    title: null,
    cover: {
        name: null,
        url: null,
    },
    photos: [],
    windowWidth: null
};

export default PlacePhotos;
