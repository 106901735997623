import { connect } from 'react-redux';

import LazyLoad from './LazyLoad';

const mapStateToProps = (state) => {
    const {
        Global: { name },
    } = state;

    return { name };
};

export default connect(mapStateToProps, {})(LazyLoad);
