import { connect } from 'react-redux';

import ToursFilter from './ToursFilter';

const mapStateToProps = (state) => {
    const {
        Global: {
            isMobile,
        },
    } = state;

    return { isMobile: isMobile ? true : window.innerHeight <= 500 };
};

export default connect(mapStateToProps, {})(ToursFilter);
