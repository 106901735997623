import React from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Pagination } from '@material-ui/lab';

import { ApiGet } from 'common/Api';
import { ParseUrl, TourEndpoint } from 'common/Endpoint';
import { Translation } from 'common/Translation';
import TourPreview from 'components/TourPreview';
import NotFound from 'components/NotFound';
import RightBlock from 'components/RightBlock';
import SeoTags from 'components/SeoTags';
import ToursFilter from 'components/ToursFilter';

function ToursList({ hideLoading, match: { url }, location: { search }, showLoading }) {
    const history = useHistory();
    const { query } = ParseUrl(search);
    const [urlWithAdditions, setUrlWithAdditions] = React.useState(url);
    const [rows, setRows] = React.useState(null);
    const [total, setTotal] = React.useState(1);
    const [parameters, setParameters] = React.useState({
        country: query.country || null,
        departure: query.departure || null,
        event: query.event || null,
        hot: query.hot || null,
        offer: query.offer || null,
        page: query.page || 1,
        transport: query.transport || null,
        type: query.type || null,
        vid: query.vid || null,
    });

    const handleChangeParameters = (data) => {
        setParameters({ ...parameters, ...data, page: 1 });
    };

    const handleChangePage = (event, value) => {
        setParameters({ ...parameters, page: value });
        event.preventDefault();
    };

    React.useEffect(() => {
        if (query.hasbeenchanged) {
            const hasbeenchanged = {
                country: query.country || null,
                departure: query.departure || null,
                event: query.event || null,
                hot: query.hot || null,
                offer: query.offer || null,
                page: query.page || 1,
                transport: query.transport || null,
                type: query.type || null,
                vid: query.vid || null,
            };

            handleChangeParameters(hasbeenchanged);
            history.push(TourEndpoint(null, hasbeenchanged));
        }
    }, [query]);

    React.useEffect(() => {
        const endpoint = TourEndpoint(null, parameters);

        showLoading();
        history.push(endpoint);

        ApiGet(endpoint.substring(4)).then((response) => {
            hideLoading();
            if (response.status) {
                setUrlWithAdditions(endpoint);
                setTotal(response.data.meta.pages);
                setRows(response.data);
            }
        });
    }, [parameters]);

    return rows && rows.data ? (
        <section className="relative">
            <SeoTags title={rows.meta.title} description={rows.meta.description} url={urlWithAdditions} />
            <div className="py-10 bg-gray-300">
                <div className="container mx-auto">
                    <div className="px-4">
                        <h1 className="text-3xl font-semibold">{rows.meta.title || Translation({ variable: 'general.offers' })}</h1>
                    </div>
                </div>
            </div>
            <div className="container mx-auto">
                <div className="flex flex-wrap items-stretch mt-10">
                    {rows.filters && (
                        <div className="w-full mb-4">
                            <ToursFilter {...rows.filters} handleChange={handleChangeParameters} values={parameters} />
                        </div>
                    )}
                    <div className="w-full lg:w-9/12">
                        <div className="flex flex-wrap items-stretch -mt-6">
                            {rows.data.length === 0 && (
                                <div className="w-full mt-6 px-4">
                                    <Alert variant="outlined" severity="warning">
                                        {Translation({ variable: 'general.no_results' })}
                                    </Alert>
                                </div>
                            )}
                            {rows.data.map((item, key) => (
                                <TourPreview key={key} size="6" {...item} />
                            ))}
                        </div>
                        <div className="pagination text-center px-4 my-4 lg:mt-8 lg:mb-0">
                            {rows.data.length > 0 && (
                                <Pagination
                                    count={parseInt(total)}
                                    page={parseInt(parameters.page || 1)}
                                    onChange={handleChangePage}
                                    color="primary"
                                />
                            )}
                        </div>
                    </div>
                    <div className="w-full lg:w-3/12">
                        <RightBlock />
                    </div>
                </div>
            </div>
        </section>
    ) : (
        <NotFound />
    );
}

ToursList.defaultProps = {
    hideLoading: () => {},
    match: {
        url: null,
    },
    location: {
        search: null,
    },
    showLoading: () => {},
};

export default ToursList;
