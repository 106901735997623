const Translate = ({ variable, values, translations }) => {
    if (translations === null) return variable;

    return variable.split('.').reduce(function (object, key) {
        let next = object[key];
        if (!next) {
            return variable;
        }
        if (typeof next === 'string' && values) {
            for (let [valueKey, valueReplace] of Object.entries(values)) {
                if (typeof valueReplace === 'object') {
                    if (!valueReplace.variable) {
                        valueReplace = null;
                    } else {
                        valueReplace = Translate({
                            ...valueReplace,
                            translations,
                        });
                    }
                }
                next = next.replace(`:${valueKey}`, valueReplace);
            }
        }
        return next;
    }, translations);
};

export const Translation = ({ variable, values, html = false }) => {
    let translations = JSON.parse(localStorage.getItem('translations'));

    if (translations === null) {
        translations = {
            general: {
                loading: 'Loading...',
            },
        };
    }

    const translated = Translate({
        variable,
        values,
        translations,
    });

    return html ? (
        <span
            dangerouslySetInnerHTML={{
                __html: translated,
            }}
        />
    ) : (
        translated
    );
};
