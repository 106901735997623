import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import dateFormat from 'dateformat';

import { NewsEndpoint } from 'common/Endpoint';
import { Translation } from 'common/Translation';

function NewsPreview({ date, slug, image, title, size }) {
    const history = useHistory();

    const handleClick = (event) => {
        history.push(NewsEndpoint(slug));
        event.preventDefault();
    };

    return (
        <div className={`w-full lg:w-${size}/12 mt-6 news-preview-block`}>
            <div className="flex flex-wrap items-stretch">
                <div className="w-full px-4 mr-auto ml-auto">
                    <div
                        className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-gray-100 cursor-pointer"
                        onClick={handleClick}
                    >
                        <img src={image.url || '/'} className="w-full align-middle rounded-t-lg" alt={title} />
                        <blockquote className="relative p-8 mb-4 min-h-40">
                            <p className="text-xl font-bold leading-relaxed mt-1 mb-4 text-gray-700">{title}</p>
                            <div className="absolute w-full bottom-0 left-0 px-4">
                                <span className="text-xs float-right mt-2">
                                    <i className="text-base fas fa-calendar"></i> {dateFormat(date, 'dd.mm.yyyy HH:MM')}
                                </span>
                                <Button color="secondary" href={NewsEndpoint(slug)}>
                                    {Translation({ variable: 'general.read' })}
                                </Button>
                            </div>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>
    );
}

NewsPreview.defaultProps = {
    date: new Date(),
    slug: null,
    image: {},
    title: null,
    size: 4,
};

export default NewsPreview;
