import React from 'react';

import { Translation } from 'common/Translation';

function NotFound() {
    return (
        <section className="relative">
            <div className="py-10 bg-gray-300">
                <div className="container mx-auto">
                    <div className="px-4">
                        <h1 className="text-3xl font-semibold">{Translation({ variable: 'general.page_not_found.title' })}</h1>
                    </div>
                </div>
            </div>
            <div className="container mx-auto">
                <div className="px-4 mt-10">{Translation({ variable: 'general.page_not_found.description' })}</div>
            </div>
        </section>
    );
}

export default NotFound;
