import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension/developmentOnly';
import App from './App';
import Reducers from 'store/reducers';
import * as serviceWorker from './serviceWorker';

const store = createStore(Reducers, devToolsEnhancer());
const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
    hydrate(
        <Provider store={store}>
            <App />
        </Provider>,
        rootElement
    );
} else {
    render(
        <Provider store={store}>
            <App />
        </Provider>,
        rootElement
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
