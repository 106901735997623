import React from 'react';

import Banner from 'components/Banner';

function BottomBlock({ banners }) {
    return (
        banners &&
        banners.length > 0 && (
            <section className="relative lg:pt-20">
                <div className="container mx-auto">
                    <div className="flex flex-wrap items-stretch">
                        {banners
                            .filter((banner) => banner.title && banner.type && banner.file)
                            .map((banner) => (
                                <div className="w-full px-4 mb-4 last:mb-0" key={banner.url}>
                                    <Banner {...banner} />
                                </div>
                            ))}
                    </div>
                </div>
            </section>
        )
    );
}

BottomBlock.defaultProps = {
    banners: [
        {
            title: null,
            url: '/',
            type: 1,
            file: null,
        },
    ],
};

export default BottomBlock;
