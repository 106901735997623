import React, { useEffect } from 'react';

import { Translation } from 'common/Translation';
import ItTourHotTourWidget from 'components/ItTourHotTourWidget';
import RightBlock from 'components/RightBlock';
import SeoTags from 'components/SeoTags';

function PartnerOffers({ match: { url } }) {
    // useEffect(() => {
    //     setTimeout(() => {
    //         const script = document.createElement('script');
    //         script.src = 'https://www.ittour.com.ua/showcase_search.jsx?id=2017D4G481244832863M5&type=103';
    //         script.id = 'HotPriceWidget';
    //         document.body.appendChild(script);
    //     }, 5 * 1000);
    // }, []);

    return (
        <section className="relative">
            <SeoTags
                title={Translation({ variable: 'general.partner_offers' })}
                description={Translation({ variable: 'general.partner_offers' })}
                url={url}
            />
            <div className="py-10 bg-gray-300">
                <div className="container mx-auto">
                    <div className="px-4">
                        <h1 className="text-3xl font-semibold">{Translation({ variable: 'general.partner_offers' })}</h1>
                    </div>
                </div>
            </div>
            <div className="container mx-auto">
                <div className="flex flex-wrap items-stretch mt-10">
                    <div className="w-full lg:w-9/12">
                        {/* <div id="showcase_hottour_v2_showcase_module"></div> */}
                        <ItTourHotTourWidget />
                    </div>
                    <div className="w-full lg:w-3/12">
                        <RightBlock />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PartnerOffers;
